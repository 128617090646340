import { auth, googleProvider, db } from "../config/firebase.js";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendSignInLinkToEmail,
  onAuthStateChanged,
  getAuth,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { useState, useEffect } from "react";
import {
  doc,
  setDoc,
  addDoc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useAuth } from '..//components/auth-email.js';
import "..//pages/allCSS.css"
import { loadGtag } from '..//components/gtag.js';

function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRegione, setSelectedRegione] = useState("");
  const [selectedComune, setSelectedComune] = useState("");
  const [atecoL1, setAtecoL1] = useState([]);
  const [selectedAtecoL1, setSelectedAtecoL1] = useState("");
  const [atecoL2, setAtecoL2] = useState("");
  const [website, setWebsite] = useState("");
  const [piva, setPiva] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isGtagLoaded, setIsGtagLoaded] = useState(false);
  const [buttonText, setButtonText] = useState('Andiamo!');
  const [showModal, setShowModal] = useState(false); // State for showing the modal
  const [modalMessage, setModalMessage] = useState('Controllando i dettagli');
  const [showSuccess, setShowSuccess] = useState(false); 

  const { setUserRole } = useAuth();
  const auth = getAuth();

  useEffect(() => {
    // Load gtag when the component mounts and set the state when it's ready
    loadGtag()
      .then(() => {
        setIsGtagLoaded(true);
      })
      .catch((error) => {
        console.error("Error loading gtag:", error);
      });
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        //console.log("User is signed in, UID:", user.uid);
        // Perform actions after user sign-in (e.g., update UI or redirect)
      } else {
        console.log("User is signed out.");
        // Handle sign-out scenario
      }
    });
  
    return () => unsubscribe(); // Cleanup on unmount
  }, []); 

  const handleSignUp = async (e) => {
    e.preventDefault();

    // Change button text and show loading modal
    setButtonText('Aspetta un attimo...');
    setIsLoading(true);
    setShowModal(true);
    setModalMessage('Controllando i dettagli...'); // Set initial modal message to "Loading..."
    setShowSuccess(false);

    try {
      if (isGtagLoaded && typeof window.gtag === 'function') {
        window.gtag('event', 'signup', { value: 1 });
      } else {
        console.error("gtag is not defined or not loaded yet");
      }

      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Validate required fields (your existing validation)
      if (!email || !password || !firstName || !lastName || !piva || !selectedRole || !selectedRegione || !selectedComune) {
        setErrorMessage('Please fill in all required fields.');
        setIsSuccess(false);
        setShowModal(false); // Hide modal on validation error
        setIsLoading(false);
        return;
      }

      if (piva.length !== 11 || !/^\d+$/.test(piva)) {
        setErrorMessage('Partita IVA must be exactly 11 digits.');
        setIsSuccess(false);
        setShowModal(false); // Hide modal on validation error
        setIsLoading(false);
        return;
      }

      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await sendEmailVerification(user);

      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        role: selectedRole,
        piva: piva,
        firstName: firstName,
        lastName: lastName,
        regione: selectedRegione,
        comune: selectedComune,
        website: website,
      });

      const apiData = await sendPivaToBackend(piva, user.uid);
      if (apiData && apiData.status === 'success') {
        await setDoc(doc(db, "users", user.uid), apiData.data, { merge: true });
      } else {
        throw new Error("API data processing failed: " + apiData.message);
      }

      setIsSuccess(true);
      //setSuccessMessage("Signup successful! Please check your email for verification.");

      // Change the modal message to "Thank You!"
      setModalMessage("Grazie per esserti iscritto!");
      setShowSuccess(true);

    } catch (error) {
      console.error("Authentication failed:", error);
      setIsSuccess(false);
      setErrorMessage(error.message);
      setShowModal(false); // Hide modal on error
    } finally {
      setButtonText('Andiamo!'); // Restore button text after process is done
      setIsLoading(false); // Stop loading
    }
  };

  const handleNavigate = () => {
    setShowModal(false); // Hide modal
    navigate("/strategy/asst_z07YzNlfWW5LFE2not8HmSZ2/"); // Navigate to strategy page
  };






  

  // const updateUserDb = async () => {
  //   const auth = getAuth();
  //   const user = auth.currentUser;
  
  //   if (user) { // Removed the emailVerified check
  //     console.log("Proceeding with Firestore operation...");
  //     try {
  //       await setDoc(doc(db, "users", user.uid), {
  //         email: user.email,
  //         firstName: firstName,
  //         lastName: lastName,
  //         role: selectedRole,
  //         regione: selectedRegione,
  //         //atecoL3: selectedAtecoL3 ? selectedAtecoL3.value : null,
  //         website: website,
  //         piva: piva,
  //       });
  //       console.log("Firestore document for user created successfully.");

  //       sendPivaToBackend(piva, user.uid);

  //       // Consider updating any success UI or navigation here, if needed
  //     } catch (firestoreError) {
  //       console.error("Firestore operation failed:", firestoreError);
  //       setErrorMessage("Failed to create user profile, please try again.");
  //     }
  //   } else {
  //     console.error("No user is signed in.");
  //     setErrorMessage("Please sign in to proceed.");
  //   }
  // };
  
//   const sendPivaToBackend = async (piva, docId) => {
//     console.log("Sending piva to backend:", piva);
//     const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//     try {
//         const payload = { piva, docId };
//         console.log("Payload being sent:", JSON.stringify(payload));
//         const response = await fetch(`${API_BASE_URL}/api/piva`, {
//             method: "POST",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(payload)
//         });
//         const responseData = await response.json();
//         console.log("Backend process triggered successfully:", responseData);
//     } catch (apiError) {
//         console.error("API call failed:", apiError);
//     }
// };
  
const updateUserDb = async () => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    console.log("Proceeding with initial Firestore operation...");
    try {
      // Assume you fetch 'selectedRole' and other fields from a form or component state
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        role: selectedRole, // Make sure this is captured or confirmed post-signup
        // other fields...
      }, { merge: true }); // Use merge to update only specified fields

      console.log("User document updated with dynamic role and other data");

      // Further backend interaction as before...
    } catch (error) {
      console.error("Firestore operation failed:", error);
      setErrorMessage("Failed to create/update user profile, please try again.");
    }
  } else {
    console.error("No user is signed in.");
    setErrorMessage("Please sign in to proceed.");
  }
};



const sendPivaToBackend = async (piva, docId) => {
  //console.log("Sending piva to backend:", piva);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  try {
    const payload = { piva, docId };
    const response = await fetch(`${API_BASE_URL}/api/piva`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload)
    });
    if (!response.ok) throw new Error("Network response was not ok");
    const responseData = await response.json();
    //console.log("Backend process triggered successfully:", responseData);
    return responseData; // Ensure this contains the status and data
  } catch (apiError) {
    console.error("API call failed:", apiError);
    return { status: "error", message: apiError.toString() };
  }
};


const handlePivaChange = (e) => {
  const value = e.target.value;

  // Update the piva state
  setPiva(value);

  // Check if the value is 11 digits long
  if (value.length !== 11 || !/^\d+$/.test(value)) {
    setError('Partita IVA must be exactly 11 digits.');
  } else {
    setError('');
  }
};
  

  const containerStyle = {
    backgroundColor: "black",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px", // Added padding to prevent content from touching the edges
  };

  const formStyle = {
    padding: "20px",
    border: "1px solid white",
    borderRadius: "5px",
    width: "100%", // The form takes up 100% of its parent's width
    maxWidth: "400px", // Limits the form's width to 400px
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  };

  // Adjusted inputStyle to fill the available width in form
  const inputStyle = {
    width: "90%", // The input should take the full width of the form
    height: "2.5rem",
    borderRadius: "5px",
    padding: "0 10px",
  };

  // Message styles seem to be for a different element, ensure they're applied correctly
  const messageStyle = {
    background: "white",
    color: "black",
    minHeight: "10vh",
    backgroundColor: "rgb(225, 141, 23)",
    fontSize: "20px",
    lineHeight: "40px",
    borderRadius: "5px",
    textAlign: "center",
    fontWeight: "bold",
    border: "2px solid white",
  };

  // This might be for wrapping the message, ensure it's used where intended
  const messageContainerStyle = {
    fontFamily: "sans-serif",
    fontSize: "20px",
    background: "black",
    color: "black",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    zIndex: 9999,
    textAlign: "left",
  };

  /*role*/
  const handleRoleChange = (e) => {
    const selectedOption = e.target.value;

    if (selectedOption === "Commercialista/Professionista") {
      setSelectedRole("commercialista");
    } else {
      setSelectedRole("azienda");
      setSelectedRole(selectedOption);
    }
  };

  /*regione*/
  const handleRegionChange = (e) => {
    const selectedOption = e.target.value;

    if (selectedOption === "Lombardia") {
      setSelectedRegione("Lombardia");
    } else {
      setSelectedRegione("Toscana");
      setSelectedRegione(selectedOption);
    }
  };

  const submitUserInfo = async () => {
    const querySnapshot = await getDocs(
      query(collection(db, "users"), where("email", "==", email))
    );

    if (!querySnapshot.empty) {
      // If the querySnapshot is not empty, a document with the email already exists
      setErrorMessage(
        "This email is already in use. Please login or reset your password."
      );
      setSuccessMessage(""); // Clear any previous success message
      return; // Exit the function to prevent creating a new document
    }

    try {
      await addDoc(collection(db, "users"), {
        email: email, // Assuming 'user' is defined in your component's state/context
        firstName: firstName, // Assuming this is defined in your state/context
        lastName: lastName, // Assuming this is defined in your state/context
        role: selectedRole, // The selected role from your form
        regione: selectedRegione, // The selected region from your form
        comune: selectedComune,
        //atecoL3: selectedAtecoL3 ? selectedAtecoL3.value : null,
        website: website,
        piva: piva 
      });
      console.log("Document successfully written!");
      setSuccessMessage("Sign up successful! Welcome to our platform.");
      setErrorMessage("");
      // Handle successful submission here (e.g., showing a success message, clearing the form, etc.)
    } catch (err) {
      console.error("Error adding document: ", err);
      setErrorMessage(
        "An error occurred. If you have already signed up, you may need to reset your password."
      );
      setSuccessMessage("");
      // Handle submission error here (e.g., showing an error message)
    }
  };

  const newEventRoleOptions = ["Commercialista/Professionista", "Azienda"];
  const newEventRegioneOptions = [
    "Abruzzo",
    "Aosta",
    "Basilicata",
    "Calabria",
    "Campania",
    "Emilia Romagna",
    "Friuli Venezia Giulia",
    "Lazio",
    "Liguria",
    "Lombardia",
    "Marche",
    "Molise",
    "Piemonte",
    "Puglia",
    "Sardinia",
    "Sicilia",
    "Trentino/Alto Adige/Sudtirol",
    "Toscana",
    "Umbria",
    "Veneto",
  ];

  /* get ateco codes from server*/
  useEffect(() => {
    console.log('API_BASE_URL:', process.env.REACT_APP_API_BASE_URL);
    //fetch("http://localhost:8080/getAtecoCodes")
    // Assuming `fetchAtecoCodes` is your function to fetch the list
    //const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/getAtecoCodes`;
    //console.log('Requesting ATECO codes from:', endpoint);
    fetch(endpoint)
    .then(response => {
      //console.log(`Response from ${endpoint}:`, response.status, response.statusText);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      //console.log('Received data:', data);
      // Transform the data into the format expected by react-select
      const transformedOptions = data.map((code) => ({
        value: code,
        label: code,
      }));
      setOptions(transformedOptions);
    })
    .catch(error => {
      console.error("Error fetching ATECO codes:", error);
    });
}, []);

/* useEffect(() => {
  if (selectedOption) {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const endpoint = `${API_BASE_URL}/api/getAtecoLevel3?level1Code=${encodeURIComponent(selectedOption.value)}`;
      
      console.log('Requesting Level 3 ATECO codes from:', endpoint);
      fetch(endpoint)
          .then((response) => {
              console.log(`Response from ${endpoint}:`, response.status, response.statusText);
              if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
              }
              return response.json();
          })
          .then((data) => {
              console.log('Received Level 3 data:', data);
              if (!Array.isArray(data) || data.length === 0) {
                  console.error("Data is not an array or is empty");
                  return;
              }
              const formattedOptions = data.map((item) => ({
                  value: item,
                  label: item,
              }));
              setOptionsL3(formattedOptions);
          })
          .catch((error) => {
              console.error("Error fetching level 3 ATECO codes:", error);
          });
  }
}, [selectedOption]);
 */
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    //console.log(`Option selected:`, selectedOption);
  };

  //const [selectedAtecoL3, setSelectedAtecoL3] = useState(null); // Ensure this matches the expected structure
  // const [optionsL3, setOptionsL3] = useState([]);

  // const handleChangeL3 = (selectedOption) => {
  //   //console.log("Selected L3 option:", selectedOption);
  //   setSelectedAtecoL3(selectedOption);
  // };

  const customStyles = {
    // Other styles...
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black", // Change text color based on selection
      backgroundColor: state.isFocused ? "blue" : "white", // Change background on focus
      // Additional styling for options can go here
    }),
    // Adjustments for valueContainer and singleValue as previously discussed
    valueContainer: (provided, state) => ({
      ...provided,
      height: "auto",
      overflow: "visible",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      overflow: "visible",
      position: "static",
      top: "auto",
      transform: "none",
      maxWidth: "none",
      whiteSpace: "normal",
      lineHeight: "normal",
    }),
    menu: (provided, state) => ({
      ...provided,
      marginTop: 0,
    }),
  };

  //console.log("Level 1 Selected:", !!selectedAtecoL1);
  //console.log("optionsL3:", optionsL3);

  const unifiedInputStyle = {
    width: "100%", // Adjusted width as per your example
    height: "40px", // Increased height
    marginBottom: "20px",
    fontSize: "18px",
    // Add other styles as needed for consistent appearance
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      ...unifiedInputStyle,
      height: "'auto'", // Overriding height for react-select control to auto
      minHeight: "40px", // Ensuring the control has a minimum height
      fontSize: "18px",
    }),
    // Add other component customizations as needed
  };

  const closePopup = () => {
    setSuccessMessage(""); // Clear success message
    setErrorMessage(""); // Clear error message
  };

  return (
    <div style={containerStyle}>
      <div style={formStyle}>
        <h2 style={{ color: "white" }}>SignUp</h2>
        <div
          className="side-by-side"
          style={{ color: "white", textAlign: "left" }}
        >
          {" "}
          Azienda / Singolo Partita Iva o Multi Partita Iva come Commercialista o Professionista? *{" "}
        </div>

        <select
          value={selectedRole}
          onChange={handleRoleChange}
          style={unifiedInputStyle}
          required
        >
          <option value="defaultRole">Scegli un ruolo</option>
          {newEventRoleOptions.map((role, index) => (
            <option
              key={index}
              value={
                role === "Commercialista/Professionista"
                  ? "commercialista" // Ensuring 'Commercialista' is set for this option
                  : role === "Azienda"
                  ? "azienda" // Using lowercase 'azienda' for this option
                  : role // For any other roles, use the role as is
              }
            >
              {role}
            </option>
          ))}
        </select>


        <div className="side-by-side" styles={customSelectStyles}>
          {" "}
          Regione *{" "}
        </div>

        <select
          value={selectedRegione}
          onChange={handleRegionChange}
          style={unifiedInputStyle}
          required
        >
          <option value="">Scegli un regione</option>
          {newEventRegioneOptions.map((regione, index) => (
            <option key={index}>{regione}</option>
          ))}
        </select>
        
        <div>
            <label>Comune *</label>
            <input
              className="boxes"
              type="text"
              placeholder="eg: Monza"
              value={selectedComune}
              onChange={(e) => setSelectedComune(e.target.value)}
              style={unifiedInputStyle}
              required
            />
          </div>

        <div>
            <label>Website </label>
            <input
              className="boxes"
              type="text"
              placeholder="lasciare vuoto se n/a"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              style={unifiedInputStyle}
            />
          </div>
          <div>
      <label>Partita Iva *</label>
      <input
        className="boxes"
        type="text"
        placeholder="es: 12345678901"
        value={piva}
        onChange={handlePivaChange}
        required
        style={unifiedInputStyle}
      />
      {error && <span style={{ color: 'red' }}>{error}</span>}
    </div>
       {/* <div>
          <label>Settore:</label>
          <Select
            value={selectedOption}
            onChange={handleChange}
            options={options}
            isMulti={false} // Ensure this is set to false or omitted, as we're not using multi-select
            className="basic-single"
            classNamePrefix="select"
            isClearable
            styles={customStyles}
          />
          <div style={{ margin: "20px" }}></div>
          <label>Sub-settore:</label>
          <Select
            value={selectedAtecoL3}
            onChange={handleChangeL3}
            options={optionsL3}
            isClearable
            className="basic-single"
            classNamePrefix="select"
            styles={customStyles}
            placeholder="Select Level 3 ATECO"
            isDisabled={optionsL3.length === 0} // Enable when options are available
          />
        </div>*/}

        <h2 style={{ color: "white" }}>Detagli di Login</h2>
        <form>
          <div>
            <label>Nome *</label>
            <input
              className="boxes"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              style={unifiedInputStyle}
            />
          </div>
          <div>
            <label>Cognome *</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              style={unifiedInputStyle}
            />
          </div>
          <div>
            <label>Email *</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={unifiedInputStyle}
            />
          </div>
          <div>
            <label>Password *</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={unifiedInputStyle}
            />
          </div>
        </form>
        <div>
        <button
        type="button"
        onClick={(e) => handleSignUp(e)}
        style={{
          background: "none",
          color: "white",
          border: "1px solid rgb(225, 141, 23)",
          borderRadius: "5px",
          padding: "10px 20px",
          cursor: "pointer",
          textAlign: "center",
        }}
        disabled={isLoading} // Disable the button while loading
      >
        {buttonText}
      </button>

      {/* Modal Popup */}
      {showModal && (
  <div className="modal-overlay-sign">
    <div className="modal-content-sign">
      <h2 className="modal-title-sign">{modalMessage}</h2> {/* Ensure text is visible */}
      {showSuccess && (
        <button
          onClick={handleNavigate}
          className="modal-button-sign"
        >
          Cominciamo
        </button>
      )}
    </div>
  </div>
)}

      {/*     {isLoading && (
        <div className="loading-spinner"></div>
      )}

          {(successMessage || errorMessage) && (
        <div className="popup-su">
          <div className="popup-content-su">
            <button className="close-button-su" onClick={closePopup}>X</button>
            {successMessage && (
              <div style={{ color: "green" }}>{successMessage}</div>
            )}
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          </div>
        </div>
      )} */}
           
          {/* {successMessage && (
            <div>
              <div style={{ color: "green" }}>{successMessage}</div>
              <button
                onClick={() => {
                  updateUserDb(piva);
                  navigate("/");
                }}
                style={{
                  marginTop: "10px",
                  padding: "10px 20px",
                  background: "blue",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Go to Home Page
              </button>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default SignUp;
