///imports
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { db, auth } from "..//..//src/config/firebase.js";
import {
  getDocs,
  getDoc,
  collection,
  addDoc,
  getFirestore,
  serverTimestamp,
  orderBy,
  where,
  query,
  doc,
  updateDoc,
  limit,
  startAfter,
  onSnapshot,
  setDoc,
  arrayUnion,
  arrayRemove 
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import "..//pages/allCSS.css";
import UserPromptModal from "..//..//src/components/userPrompt.js";
import App from "..//App.js";
import { useParams } from "react-router-dom";
import IncentiveSearch from "./agent2.js";
import Typewriter from "..//components/Typewriter.js";
import SSEDisplay from "..//components/SSEDisplay.js";
import renderCriteria from "..//components/criteria.js";
import RatingComponent from "..//components/RatingComponent.js";
//import LongPollDisplay from '..//components/LongPolling.js';
import DOMPurify from "dompurify";
import clientlogo from "..//../src/technosida.png";
import { useAuth } from "../../src/components/auth-email.js";

function PostSignup() {
  const [goalInput, setGoalInput] = useState(""); // State to hold input from the user
  const [goals, setGoals] = useState([]); // State to store goals fetched from Firebase
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  //console.log(threadId); // This should log the actual threadId from the URL
  const navigate = useNavigate();
  const headerStyles = {
    width: "100%",
    backgroundColor: "black",
    color: "white",
    padding: "10px",
    fontFamily: "'Poppins', sans-serif",
    textAlign: "center",
    wordSpacing: "6px",
    fontSize: "35px",
    //animation: "flash 3s infinite",
  };

  ////////////////////////////////////////////////////////////////////////////////////
  //text entry to fb db
  const [newQ1, setNewQ1] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const questionsCollectionRef = collection(db, "questions");
  const threadsCollectionRef = collection(db, "threads");
  const [lastVisible, setLastVisible] = useState(null);
  const location = useLocation();
  const { title } = location.state || { title: "Default Title" };
  const { assistant, threadId } = useParams();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [document, setDocument] = useState(null);
  const [submissionSource, setSubmissionSource] = useState("manual");
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [atecoL3, setAtecoL3] = useState("");
  const [companyAteco, setCompanyAteco] = useState("");
  const [regione, setRegione] = useState("");
  const [firstName, setFirstName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [companyEmployees, setCompanyEmployees] = useState("");
  const [currentuserID, setCurrentUserID] = useState("");
  const [companyTurnover, setCompanyTurnover] = useState(0);
  const [Descrizione, setDescrizione] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [incentives, setIncentives] = useState("");
  const [incentivesCount, setIncentivesCount] = useState(0);
  const [totalIncentives, setTotalIncentives] = useState(0);
  const [totalFunding, setTotalFunding] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [incentivesData, setIncentivesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoadingIncentives, setIsLoadingIncentives] = useState(true);
  const [isLoadingFunding, setIsLoadingFunding] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);
  const [cw, setCw] = useState("");
  const [lastDataUpdate, setLastDataUpdate] = useState(Date.now());
  const [shouldRerender, setShouldRerender] = useState(false);
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const [shouldStartConversation, setShouldStartConversation] = useState(false);
  const [showAdditionalContent, setShowAdditionalContent] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setIsAuthenticated(!!user);
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  /*get user data & ids*/
  useEffect(() => {
    if (currentUser) {
      fetchUserData();
    }
  }, [currentUser]);

  async function fetchUserData() {
    if (currentUser && currentUser.email) {
      try {
        const usersCollectionRef = collection(db, "users");
        const q = query(
          usersCollectionRef,
          where("email", "==", currentUser.email)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          const userDoc = querySnapshot.docs[0];
          setAtecoL3(userData.companyAteco);
          setRegione(userData.regione);

          /*  await sendUserInfo(
            userData.regione,
            userData.companySize,
            userData.companyTurnover,
            userData.companyEmployees,
            userData.companyAteco,
          ); */

          setFirstName(userData.firstName);
          setCurrentUserID(userDoc.id);
          setCompanyName(userData.companyName);
          setCompanySize(userData.companySize);
          setCompanyEmployees(userData.companyEmployees);
          setCompanyTurnover(userData.companyTurnover);
          setIsUserDataLoaded(true);
          setCompanyAteco(userData.companyAteco);
        } else {
          console.log("No user data found for email:", currentUser.email);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    } else {
      console.log("currentUser is not defined yet");
    }
  }

  async function fetchIncentivesData(
    atecoL3,
    regione,
    companySize,
    companyTurnover,
    companyEmployees,
    currentuserID
  ) {
    const extractBaseCode = (ateco) => {
      const numericalPartMatch = ateco.match(/^(\d{2}\.\d)(\d?)\b/);
      let numericalPart = "";

      if (numericalPartMatch) {
        const beforeDecimal = numericalPartMatch[1];
        const afterDecimal = numericalPartMatch[2];
        numericalPart =
          afterDecimal.length === 0
            ? `${beforeDecimal}0`
            : `${beforeDecimal}${afterDecimal}`;
      }

      return numericalPart;
    };

    const requestBody = {
      codicel3: atecoL3 ? extractBaseCode(atecoL3) : "",
      regione: regione || "",
      companySize,
      companyTurnover,
      companyEmployees,
      currentuserID,
    };

    //console.log("request:", requestBody);

    try {
      setLoading(true); // Start loading

      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/incentives`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      let fetchedData = await response.json();

      fetchedData = fetchedData.map((incentive) => ({
        ...incentive,
        filtered: 1,
      }));

      const incentiveIds = fetchedData.map(
        (incentive) => incentive.ID_Incentivo
      );

      // Update React state with the new incentives data
      setFilteredData(fetchedData);
      fetchIncentiviByIds(incentiveIds);
      localStorage.setItem("filteredData", JSON.stringify(fetchedData));

      //console.log("Incentives data received from server:", fetchedData);
    } catch (error) {
      console.error("Error fetching incentives data:", error);
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  }

  const fetchIncentiviByIds = async (ids) => {
    const db = getFirestore();
    const incentiviCollection = collection(db, "incentivi");

    const promises = ids.map((id) => {
      const docRef = doc(incentiviCollection, id);
      return getDoc(docRef);
    });

    try {
      const documentSnapshots = await Promise.all(promises);
      const documents = documentSnapshots.map((docSnapshot) =>
        docSnapshot.exists()
          ? { id: docSnapshot.id, ...docSnapshot.data() }
          : null
      );

      return documents;
    } catch (error) {
      console.error("Error fetching documents by IDs:", error);
      return []; // Returning an empty array on error ensures the UI can handle this gracefully
    }
  };

  useEffect(() => {
    if (currentUser && currentUser.email) {
      fetchUserData();
    }
  }, [currentUser]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  //////////* new thread*/

  const startConversationHandler = async () => {
    if (!isUserDataLoaded) {
      console.log("User data not loaded yet.");
      return;
    }

    await startNewConversation();
  };

  const startNewConversation = async (
    assistant = "asst_z07YzNlfWW5LFE2not8HmSZ2"
  ) => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const userMessage = "Ciao, vorrei iniziare una nuova conversazione.";

      const requestBody = {
        message: userMessage,
        assistant: assistant,
      };

      console.log("Request Body:", requestBody);

      const response = await fetch(`${API_BASE_URL}/api/start_new_convo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      const newThreadId = data.thread_id;

      // Store the thread ID in local storage (optional)
      localStorage.setItem("currentThreadId", newThreadId);

      // After receiving the thread ID, send user info
      await sendUserInfo(
        newThreadId,
        regione,
        companySize,
        companyTurnover,
        companyEmployees,
        companyAteco,
        userId
      );

      // Construct the new URL with the thread ID
      const newUrl = `${location.pathname}${newThreadId}`;

      // Force page reload with the new URL
      window.location.replace(newUrl);
    } catch (error) {
      console.error("Error starting a new conversation:", error);
    }
  };

  const extractBaseCode = (ateco) => {
    // Extract only the numeric part (before any dash or text)
    const numericPart = ateco.split(" - ")[0].trim();

    // Handle cases with one decimal place (e.g., '27.9')
    if (/^\d{2}\.\d$/.test(numericPart)) {
      // Append '0' to make it '27.90'
      return numericPart + "0";
    }

    // Handle cases with two or more decimal places (e.g., '70.2209')
    if (/^\d{2}\.\d{2,}/.test(numericPart)) {
      // Truncate to two decimal places
      return numericPart.slice(0, 5);
    }

    // If no match, return the original numeric part
    return numericPart;
  };

  //const sendUserInfo = async (threadId, goals, companySize, regione, atecoL3, assistant, user_id) => {
  //// send user info
  const sendUserInfo = async (
    threadId,
    regione,
    companySize,
    companyTurnover,
    companyEmployees,
    companyAteco,
    userId
  ) => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

      // Use extractBaseCode to process companyAteco
      const baseCode = companyAteco ? extractBaseCode(companyAteco) : "";

      // Construct the userInfo object
      const userInfo = {
        thread_id: threadId,
        regione: regione,
        companySize: companySize,
        companyTurnover: companyTurnover,
        companyEmployees: companyEmployees,
        companyAteco: companyAteco,
        codicel3: baseCode,
        user_id: userId,
      };

      console.log("User Info:", userInfo);

      // Send the userInfo to the backend
      const response = await fetch(`${API_BASE_URL}/api/send_user_info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userInfo),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      console.log("User info sent successfully:", data.message);
    } catch (error) {
      console.error("Error sending user info:", error);
    }
  };

  // Check if threadId exists in the URL when the component loads
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const threadIdInUrl = pathSegments.find((segment) =>
      segment.startsWith("thread_")
    );

    if (!threadIdInUrl) {
      // No thread ID found, set flag to start a new conversation after data is loaded
      setShouldStartConversation(true);
    } else {
      console.log("Thread ID already exists in URL:", threadIdInUrl);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isUserDataLoaded && shouldStartConversation) {
      startConversationHandler();
      setShouldStartConversation(false); // Reset the flag after starting the conversation
    }
  }, [isUserDataLoaded, shouldStartConversation]);

  ///////

  useEffect(() => {
    // Call getQuestionList when the component mounts
    getQuestionList();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  // The rest of your component...

  const getQuestionList = async () => {
    try {
      const userId = auth.currentUser ? auth.currentUser.uid : null;
      if (!userId) {
        console.error("User is not authenticated.");
        return;
      }

      // Create a query against the collection.
      const q = query(
        questionsCollectionRef,
        where("userId", "==", userId),
        orderBy("timestamp", "asc")
      );
      const querySnapshot = await getDocs(q);

      const filteredData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setQuestionList(filteredData);
    } catch (err) {
      console.error("Error fetching questions:", err);
    }
  };

  const onSubmitQuestion = async () => {
    try {
      if (!newQ1.trim()) {
        console.log("Empty question, exiting.");
        alert("Please enter a message.");
        return;
      }

      setSubmissionSource("manual");
      // Reset the input field
      setNewQ1("");

      const userId = auth.currentUser ? auth.currentUser.uid : null;
      //console.log("Current user ID:", userId);
      if (!userId) {
        console.error("User is not authenticated.");
        return;
      }

      //console.log("Parameters:", { assistant, threadId });
      //console.log("Database references:", { questionsCollectionRef, threadsCollectionRef });

      // Add the question to Firestore and get the reference to the newly added document
      //console.log("Attempting to add a document to questions collection...");
      const docRef = await addDoc(questionsCollectionRef, {
        q1: newQ1,
        userId: userId,
        timestamp: serverTimestamp(),
        threadId: threadId,
        response: "",
        source: "strategy",
      });

      //console.log("Document added with ID:", docRef.id);
      localStorage.setItem("currentDocId", docRef.id); // Store docId in local storage

      //console.log("Attempting to add a document to threads collection...");
      const docReff = await addDoc(threadsCollectionRef, {
        userId: userId,
        threadId: threadId,
        title: title,
        assistant: assistant,
      });

      //console.log("Document added with ID:", docReff.id);

      // Call the API after successfully adding the question
      await fetchCompletionGoals(newQ1, threadId, assistant, docRef.id, goals);
      //checkForResponse(threadId, docRef.id);

      //getResponse(threadId, docRef.id);

      setNewQ1("");

      // Optionally refresh the question list to include the latest question
      await getQuestionList();
    } catch (err) {
      console.error("Error submitting the question:", err);
    }
  };

  useEffect(() => {
    if (readyToSubmit && newQ1.trim()) {
      onSubmitQuestion();
      setReadyToSubmit(false); // Reset the trigger
    }
  }, [readyToSubmit, newQ1]);

  const [showUserPrompt, setShowUserPrompt] = useState(false);
  useEffect(() => {
    const userHasVisited = localStorage.getItem("userHasVisited");
    if (!userHasVisited) {
      setShowUserPrompt(true);
    }
  }, []);
  const handleClose = () => {
    localStorage.setItem("userHasVisited", "true");
    setShowUserPrompt(false);
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Assume auth is your authentication instance (e.g., from Firebase)
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////

  const [question, setQuestion] = useState("");
  const [docId, setDocId] = useState("");
  //const [jsonData, setJsonData] = useState(Opendata); // Ensure Opendata is defined
  const [apiResponse, setApiResponse] = useState("");
  const [messages, setMessages] = useState([
    {
      role: "system",
      content:
        "You are a helpful assistant with good general knowledge for checking facts",
      file_ids: "123",
    },
  ]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  /////message back and forth logic

  //////// Send and get potential goals
  const fetchCompletionGoals = async (
    question,
    threadId,
    assistant,
    docId,
    goals
  ) => {
    /* console.log(
      "Sending question to server:",
      question,
      threadId,
      assistant,
      docId,
      goals
    ); */

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${API_BASE_URL}/api/fetchCompletionServerGoals`;

    try {
      // Create the request body directly within the fetch call for clarity and simplicity
      const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          question: question,
          threadId: threadId,
          assistant: assistant,
          docId: docId,
          goals: goals,
        }),
      });

      /* console.log(
          "Request body:",
          JSON.stringify({
            question: question,
            threadId: threadId,
            assistant: assistant,
            docId: docId,
          })
        ); */

      //console.log("Response received. Status:", response.status);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseText = await response.text(); // Fetch the raw response text
      //console.log("Raw response text:", responseText);

      try {
        const jsonResponse = JSON.parse(responseText);
        //console.log("Parsed JSON response:", jsonResponse);

        const { thread_id } = jsonResponse;
        //console.log("Received thread_id & assistant:", thread_id, assistant);

        // Optionally update the document with the new thread ID if necessary
        if (!threadId) {
          await updateDoc(doc(questionsCollectionRef, docId), {
            threadId: thread_id,
          });
          console.log("Updated document with new threadId.");
        }

        if (!assistant) {
          await updateDoc(doc(threadsCollectionRef, docId), {
            assistant: assistant,
          });
          console.log("Updated document with new assistant.");
        }

        // SSEDisplay(thread_id, docId);
        //console.log("Ready to display SSE stream.");
      } catch (parseError) {
        console.error("Error parsing JSON:", parseError);
      }
    } catch (error) {
      console.error("Error during fetch operation:", error);
    }
  };

  const pollForResponse2 = async (
    thread_id,
    setGoalsState,
    setLoading,
    setError
  ) => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${API_BASE_URL}/api/get_response2`;

    const maxAttempts = 3; // e.g., 30 attempts
    const interval = 2000; // 2 seconds
    let attempts = 0;

    const poll = async () => {
      attempts += 1;
      //console.log(`Polling attempt ${attempts} for thread_id: ${thread_id}`);

      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ thread_id: thread_id }),
        });

        //console.log(`Received status ${response.status} for thread_id: ${thread_id}`);

        if (response.status === 200) {
          const jsonResponse = await response.json();
          //console.log("Parsed JSON response:", jsonResponse);

          const { response2 } = jsonResponse;

          if (response2 && Array.isArray(response2)) {
            //console.log("response2 is available:", response2);
            setGoalsState(response2);
            setLoading(false);
            return;
          } else {
            //console.log("response2 is not in expected format or missing:", response2);
          }
        } else if (response.status === 202) {
          console.log(`response2 not yet available. Attempt: ${attempts}`);
        } else {
          console.warn(
            `Unexpected status code ${response.status}. Attempt: ${attempts}`
          );
        }
      } catch (error) {
        console.error(
          `Error during polling for response2 on attempt ${attempts}:`,
          error
        );
        setError("Failed to fetch goals. Please try again.");
        setLoading(false);
        return;
      }

      if (attempts < maxAttempts) {
        console.log(
          `Scheduling next polling attempt in ${interval / 1000} seconds.`
        );
        setTimeout(poll, interval);
      } else {
        console.warn("Polling for response2 timed out after maximum attempts.");
        setError("Fetching goals timed out. Please try again later.");
        setLoading(false);
      }
    };

    poll();
  };

  const fetchResponse2 = async (
    thread_id,
    setGoalsState,
    setLoading,
    setError
  ) => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${API_BASE_URL}/api/get_response2`;

    try {
      setLoading(true);
      setError(null);

      const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ thread_id: thread_id }),
      });

      //console.log(`Received status ${response.status} for thread_id: ${thread_id}`);

      if (response.status === 200) {
        const jsonResponse = await response.json();
        //console.log("Parsed JSON response:", jsonResponse);

        const { response2 } = jsonResponse;

        if (response2 && Array.isArray(response2)) {
          //console.log("response2 is available:", response2);
          setGoalsState(response2);
          setLoading(false);
          return;
        } else {
          console.log("response2 is not in expected format:", response2);
        }
      } else if (response.status === 202) {
        console.log("response2 not yet available.");
      } else {
        console.warn(`Unexpected status code ${response.status}.`);
      }

      // If response2 is not yet available, initiate polling
      pollForResponse2(thread_id, setGoalsState, setLoading, setError);
    } catch (error) {
      console.error("Error during fetchResponse2 operation:", error);
      setError("Failed to fetch goals. Please try again.");
      setLoading(false);
    }
  };

  const GoalsBox = ({ thread_id }) => {
    const [goals, setGoals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
      if (!thread_id) {
        console.error("No thread_id provided to GoalsBox.");
        setError("No thread identifier provided.");
        return;
      }

      //console.log(`GoalsBox mounted with thread_id: ${thread_id}`);

      const fetchAndPoll = async () => {
        // Initial fetch
        await fetchResponse2(thread_id, setGoals, setLoading, setError);
      };

      fetchAndPoll();
    }, [thread_id]);

    // Add this useEffect to log goals whenever they change
    useEffect(() => {
      //console.log("Current goals state:", goals);
    }, [goals]);

    const addGoal = (goal) => {
      handleAddGoal(userId, goal);
      setGoals((prevGoals) => prevGoals.filter((g) => g !== goal));
    };

    return (
      <div className="goals-box">
        <h3>AI Suggested Goals:</h3>
      {loading && <p>Loading goals...</p>}
      {error && <p className="error-message">{error}</p>}
      {!loading && !error && goals.length > 0 && (
        <ul className="goal-list">
          {goals.map((goal, index) => (
            <li key={index} className="goal-list-item">
              <p>{goal}</p>
              <div className="icon-container">
                <span
                  className="tick-icon"
                  onClick={() => addGoal(goal)} // Call addGoal on click
                  style={{ cursor: 'pointer' }} // Optional: make the icon look clickable
                >
                  +
                </span>
              </div>
            </li>
          ))}
        </ul>
      )}
      {!loading && !error && goals.length === 0 && <p>No goals available.</p>}
    </div>
    );
  };

  // Similar as before, sends the question to your Flask backend
  // const fetchCompletion = async (question, threadId, assistant, docId) => {
  //   /* console.log(
  //       "Sending question to server:",
  //       question,
  //       threadId,
  //       assistant,
  //       docId
  //     ); */

  //   const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  //   const endpoint = `${API_BASE_URL}/api/fetchCompletionServer`;

  //   try {
  //     // Create the request body directly within the fetch call for clarity and simplicity
  //     const response = await fetch(endpoint, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         question: question,
  //         threadId: threadId,
  //         assistant: assistant,
  //         docId: docId,
  //       }),
  //     });

  //     /* console.log(
  //         "Request body:",
  //         JSON.stringify({
  //           question: question,
  //           threadId: threadId,
  //           assistant: assistant,
  //           docId: docId,
  //         })
  //       ); */

  //     //console.log("Response received. Status:", response.status);

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const responseText = await response.text(); // Fetch the raw response text
  //     //console.log("Raw response text:", responseText);

  //     try {
  //       const jsonResponse = JSON.parse(responseText);
  //       //console.log("Parsed JSON response:", jsonResponse);

  //       const { thread_id } = jsonResponse;
  //       //console.log("Received thread_id & assistant:", thread_id, assistant);

  //       // Optionally update the document with the new thread ID if necessary
  //       if (!threadId) {
  //         await updateDoc(doc(questionsCollectionRef, docId), {
  //           threadId: thread_id,
  //         });
  //         console.log("Updated document with new threadId.");
  //       }

  //       if (!assistant) {
  //         await updateDoc(doc(threadsCollectionRef, docId), {
  //           assistant: assistant,
  //         });
  //         console.log("Updated document with new assistant.");
  //       }

  //       // SSEDisplay(thread_id, docId);
  //       //console.log("Ready to display SSE stream.");
  //     } catch (parseError) {
  //       console.error("Error parsing JSON:", parseError);
  //     }
  //   } catch (error) {
  //     console.error("Error during fetch operation:", error);
  //   }
  // };

  const textAreaRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    //console.log("Submitting question:", newQ1);

    if (submissionSource === "questionButton") {
      // If the submission source is the question button, wait for the state update
      setTimeout(() => {
        // Using setTimeout as a simple way to wait for the next event loop
        onSubmitQuestion();
      }, 0);
    } else {
      // If the user manually submits, proceed immediately
      setReadyToSubmit(true);
    }

    // Reset the textarea's height directly using the ref
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
    }
  };

  const [currentThreadId, setCurrentThreadId] = useState(null);
  const [lastThreads, setLastThreads] = useState([]);

  const filteredQuestions = questionList.filter(
    (question) => question.threadId === threadId
  );

  const [isLoading, setIsLoading] = useState(false);

  const [threadTitle, setThreadTitle] = useState("");

  useEffect(() => {
    const fetchThreadTitle = async () => {
      if (threadId) {
        // Ensure threadId is not undefined or empty
        const threadsCollectionRef = collection(db, "threads");
        const q = query(
          threadsCollectionRef,
          where("threadId", "==", threadId)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Assuming each threadId value is unique and only one document should match
          const docSnap = querySnapshot.docs[0];
          setThreadTitle(docSnap.data().title); // Update state with the fetched title
        } else {
          //console.log("No such document with threadId:", threadId);
        }
      }
    };

    fetchThreadTitle();
  }, [threadId]); // Re-fetch whenever threadId changes

  //////////////////////////////////////////////////////////////////////////////////////////////////
  ///////GOALS

  //const { currentUser } = useAuth(); // Using a custom hook to get the current user
  const [newGoal, setNewGoal] = useState("");
  const [editedGoals, setEditedGoals] = useState([]); // Editable goals
  const [proposedGoals, setProposedGoals] = useState([]); // Proposed goals from AI
  const [editedProposedGoals, setEditedProposedGoals] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser && currentUser.email) {
        const db = getFirestore();
        const usersCollectionRef = collection(db, "users");
        const q = query(
          usersCollectionRef,
          where("email", "==", currentUser.email)
        );

        try {
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const userData = userDoc.data();

            // Fetch goals for the user
            fetchGoals(userDoc.id);
          } else {
            console.log("No user data found for email:", currentUser.email);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    if (currentUser && currentUser.email) {
      fetchUserData();
    }
  }, [currentUser]); // This useEffect will run every time the currentUser changes

  // Function to fetch goals for a given user ID
  const fetchGoals = async (userId) => {
    const db = getFirestore();
    const userGoalsRef = doc(db, "goals", userId); // Goals are stored in a document with the user's ID

    try {
      const docSnapshot = await getDoc(userGoalsRef);

      if (docSnapshot.exists()) {
        const goalsData = docSnapshot.data().goals; // Assuming goals are stored as an array in the "goals" field
        setGoals(goalsData || []); // Set the fetched goals in state
      } else {
        console.log("No goals found for user:", userId);
        setGoals([]); // If no goals, initialize an empty array
      }
    } catch (error) {
      console.error("Error fetching goals:", error);
    }
  };

  // Function to handle adding a new goal
  const handleAddGoal = async (userId, newGoal) => {
    const db = getFirestore();
    const docRef = doc(db, "goals", userId); // Reference to the user's goals document

    try {
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        // If the document exists, add the new goal to the goals array
        await updateDoc(docRef, {
          goals: arrayUnion(newGoal), // Use arrayUnion to add the new goal to the existing goals
        });
        console.log("Goal added successfully");
      } else {
        // If the document doesn't exist, create it with the first goal
        await setDoc(docRef, { goals: [newGoal] });
        console.log("Document created and goal added successfully");
      }

      // Re-fetch goals after adding a new one
      fetchGoals(userId); // Ensure we fetch the updated goals after adding a new one
    } catch (error) {
      console.error("Error adding goal:", error);
    }
  };

  // Function to handle goal submission (clicking the Add Goal button)
  const submitGoal = () => {
    if (newGoal.trim() && currentUser) {
      // Call handleAddGoal with userId and new goal
      handleAddGoal(currentUser.uid, newGoal);
      setNewGoal(""); // Clear the input after submitting
    } else {
      console.error("Invalid goal or user not authenticated.");
    }
  };

  const handleRemoveGoal = async (goal) => {
    const db = getFirestore();
    const docRef = doc(db, "goals", userId); // Reference to the user's goals document

    try {
      await updateDoc(docRef, {
        goals: arrayRemove(goal), // Remove the goal from the goals array
      });
      console.log("Goal removed successfully");

      // Update the state to reflect the change
      setGoals((prevGoals) => prevGoals.filter((g) => g !== goal));
    } catch (error) {
      console.error("Error removing goal:", error);
    }
  };

  const handleGoalChange = (index, event) => {
    const updatedGoals = [...editedGoals];
    updatedGoals[index] = event.target.value;
    setEditedGoals(updatedGoals);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////
  ///////FORMATTING
  const formatDescription = (desc) => {
    if (typeof desc === "string") {
      desc = desc.trim().replace(/\r\n?/g, "\n"); // Normalize line endings
      desc = desc.replace(/【\d+:\d+†source】/g, "");
      desc = desc.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank" style="color: white; text-decoration: underline;">$1</a>'
      );

      let formattedText = ""; // Initialize the formatted text.
      let paragraphs = desc.split(/---\n*/); // Split by '---' for new paragraphs.

      paragraphs.forEach((paragraph, index) => {
        if (index > 0) {
          formattedText += "<p>"; // Start a new paragraph for each new section defined by '---'
        }

        // Handle bold text and convert line breaks within a paragraph into <br>
        let lines = paragraph.split("\n");
        lines = lines.map((line) => {
          if (line.startsWith("###")) {
            // Remove '###' and format the text
            const textWithoutHash = line.substring(3).trim(); // Remove the '###' and any leading/trailing whitespace
            return `<span style="font-size: 20px;">${textWithoutHash}</span>`; // Apply styling directly
          } else {
            // Replace "**text**" with "<strong>text</strong>"
            return line.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
          }
        });

        formattedText += lines.join("<br>"); // Join lines with <br> tags

        if (index > 0) {
          formattedText += "</p>"; // Close paragraph tag only if it's not the first section
        }
      });

      // Sanitize the final HTML string to ensure it's safe to render
      const safeHtml = DOMPurify.sanitize(formattedText, {
        USE_PROFILES: { html: true },
      });

      return safeHtml; // Return the sanitized HTML
    }
    return "No description available"; // Fallback if input is not a string
  };

  const formatDate = (dateString) => {
    return dateString ? dateString.split("T")[0] : "N/A"; // Default to 'N/A' if dateString is undefined
  };

  const toggleAdditionalContent = () => {
    setShowAdditionalContent(!showAdditionalContent);
  };
  ////////////////////////////////////////////////////////////////////////////////////////

  const [criteriaStates, setCriteriaStates] = useState({});
  //const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (document) {
      const initialStates = {};
      for (let i = 1; i <= 15; i++) {
        const criteriaKey = `criteria_${i}`;
        if (
          document[criteriaKey] &&
          document[criteriaKey] !== "Not specified"
        ) {
          initialStates[criteriaKey] = "Non"; // Default to "Non" instead of leaving it undefined
        }
      }
      setCriteriaStates(initialStates);
    }
  }, [document]);

  /*  useEffect(() => {
      //console.log("Checking all criteria states:", criteriaStates);
      const allSelectedSi = Object.values(criteriaStates).every(
        (state) => state === "Si"
      );
      //console.log("Are all selected 'Si'? :", allSelectedSi);
      setShowPopup(allSelectedSi);
    }, [criteriaStates]); */

  const handleButtonClick = (
    buttonType,
    criterionKey,
    criterionDescription
  ) => {
    if (buttonType === "?") {
      const fullText = `cosa significa esattamente questo criterio: ${criterionDescription}`;
      setNewQ1(fullText);
      setSubmissionSource("questionButton");
      if (textAreaRef.current) {
        textAreaRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      // Assuming handleSubmit takes an event argument
      handleSubmit(new Event("submit"));
    } else {
      setCriteriaStates((prevStates) => {
        const newState = {
          ...prevStates,
          [criterionKey]: buttonType,
        };
        //console.log(`Updated criteria state for ${criterionKey} to ${buttonType}`, newState);
        return newState;
      });
    }
  };

  const renderCriteria = () => {
    let criteriaComponents = [];
    for (let i = 1; i <= 15; i++) {
      const criteriaKey = `criteria_${i}`;
      if (document[criteriaKey] && document[criteriaKey] !== "Not specified") {
        criteriaComponents.push(
          <Criterion
            key={criteriaKey}
            criterionName={criteriaKey}
            description={document[criteriaKey]}
            selected={criteriaStates[criteriaKey]}
            onButtonClick={(buttonType) =>
              handleButtonClick(buttonType, criteriaKey, document[criteriaKey])
            }
          />
        );
      }
    }
    return criteriaComponents;
  };

  const Criterion = ({
    criterionName,
    description,
    selected,
    onButtonClick,
  }) => {
    const buttonStyle = (buttonType) => ({
      backgroundColor:
        buttonType === selected
          ? buttonType === "Si"
            ? "green"
            : buttonType === "?"
            ? "orange"
            : "red"
          : "",
      color: buttonType === selected ? "white" : "black",
      margin: "0 4px",
      cursor: "pointer",
    });

    return (
      <div className="criteria">
        <p>{description}</p>
        <div>
          <button style={buttonStyle("Si")} onClick={() => onButtonClick("Si")}>
            Si
          </button>
          <button
            style={buttonStyle("Non")}
            onClick={() => onButtonClick("Non")}
          >
            Non
          </button>
          <button style={buttonStyle("?")} onClick={() => onButtonClick("?")}>
            Chiede all'agente
          </button>
        </div>
      </div>
    );
  };

  const [fullResponse, setFullResponse] = useState(null);

  const [doceId, setDoceId] = useState(localStorage.getItem("currentDocId")); // Initially fetch from local storage

  // Polling local storage for changes in docId
  useEffect(() => {
    const interval = setInterval(() => {
      const currentDocId = localStorage.getItem("currentDocId");
      if (doceId !== currentDocId) {
        setDoceId(currentDocId); // Update state if there's a change
      }
    }, 1000); // checks every second

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [doceId]);

  // Firestore document listener effect
  useEffect(() => {
    if (doceId) {
      const docRef = doc(db, "questions", doceId);

      const unsubscribe = onSnapshot(
        docRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data.response) {
              // Check if response field is populated
              //console.log("Response updated:", data.response);
              setFullResponse(data.response); // Store the full response in state
            } else {
              //console.log("Response field is not yet populated.");
              setFullResponse(null); // Reset if response is not ready
            }
          } else {
            console.log("No such document!");
          }
        },
        (error) => {
          console.error("Error listening to the document:", error);
        }
      );

      return () => unsubscribe(); // Clean up the listener when the component unmounts or doceId changes
    }
  }, [doceId]);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////start of JSX

  return (
    <div className="searchy-container">
      {/*   <button
      onClick={() => navigate("/search")}
      className="threadunic-button"
    >
      🔙
    </button> */}
     <div className="threadunic-container">
      <div
        style={{
          position: "relative",
          textAlign: "left",
          border: "none",
          marginRight: "30px",
          marginTop: "10px",
        }}
      >
        {/* <div>
            {document ? (
              <div>
                <CustomTooltip tooltipText="Il punteggio è una valutazione ponderata che tiene conto dei benefici chiave dell'incentivo, della complessità del processo di applicazione e dell'ambito geografico">
                  <h2 style={{ marginTop: "10px", marginLeft: "5px" }}>
                    Ottima scelta! {document.RatingInterest}
                  </h2>
                </CustomTooltip>
                <div className="incentivethread-subdetails">
                  <p style={{ fontWeight: "bold" }}>
                    <strong> </strong> {document.Titolo}
                  </p>
                  <p>
                    <strong>Apertura:</strong>{" "}
                    {formatDate(document.Data_apertura)}
                  </p>
                  <p>
                    <strong>Chiusura:</strong>{" "}
                    {formatDate(document.Data_chiusura)}
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    <strong></strong> {document.KeyBenefit}
                  </p>
                </div>
              </div>
            ) : (
              <p>No data available.</p>
            )}
          </div> */}
        <h1> Pianificatore di Obiettivi Strategici</h1>
        <h2>Quali sono gli obiettivi strategici che hai per il business.</h2>
        <h3 style={{ fontSize: "20px", marginLeft: "5px" }}></h3>
        <div className="goals-container">
          <h3>Obiettivi Attuali:</h3>
          <ul className="goal-list">
            {goals.map((goal, index) => (
              <li key={index} className="goal-list-item">
                <p>{goal}</p>
                <div className="icon-container">
                  <span className="tick-icon">&#10003;</span>
                  <span
                  className="cross-icon"
                  onClick={() => handleRemoveGoal(goal)} // Call handleRemoveGoal on click
                  style={{ cursor: 'pointer', marginLeft: "20px" }} // Optional: make the icon look clickable
                >
                  &#10006; {/* Cross icon for removing goal */}
                </span>
                </div>
              </li>
            ))}
          </ul>
          {/* <input
            type="text"
            className="goal-input"
            value={newGoal}
            onChange={(e) => setNewGoal(e.target.value)} // Capture user input
            placeholder="Aggiungere un nuovo"
          />
          <button className="goal-button" onClick={submitGoal}>
            +
          </button> */}
          
          <div style={{color: "black", marginLeft: "20px"}}>
            Aggiungere un nuovo con l'Agente
          <button
            className="goal-button"
            style={{ marginLeft: "30px"}}
            onClick={toggleAdditionalContent}
            aria-label="Mostra/Nascondi"
          >
            {showAdditionalContent ? "-" : "+"}
          </button>
          </div>
        </div>
        {showAdditionalContent && (
          <div>
            <div>
              <div>
                <div style={{ margin: "50px" }}>
                  <Typewriter
                    text={
                      " Prima Domanda, cosa vuoi fare per quanto rigaurda finanziamento?"
                    }
                    speed={40}
                  />
                </div>
                {!isAuthenticated && <UserPromptModal />}
                <div
                  style={{
                    margin: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                ></div>
              </div>
              <div className="App">
                {/*   <SimpleQuestionDisplay threadId={threadId} /> */}
              </div>
              <div className="full-display">
                <div className="questions-display">
                  {filteredQuestions.map((question, index) => {
                    // Convert timestamp to JavaScript Date object
                    const questionDate = question.timestamp?.toDate();

                    // Format the date as a simple string for comparison (ignoring time part)
                    const questionDateString =
                      questionDate?.toLocaleDateString();

                    // Check if this is the first question of a new day
                    const isFirstQuestionOfDay =
                      index === 0 ||
                      filteredQuestions[index - 1]?.timestamp
                        ?.toDate()
                        ?.toLocaleDateString() !== questionDateString;

                    const isLastQuestion =
                      index === filteredQuestions.length - 1;

                    return (
                      <div key={question.id} className="question-item">
                        {/* Display the timestamp if it's the first question of the day */}
                        {isFirstQuestionOfDay && (
                          <div className="date-display">
                            {/*   <small>{questionDateString}</small> */}
                          </div>
                        )}
                        <div className="question-text">
                          <div
                            style={{
                              lineHeight: "1.6",
                              background: "#7e8ee6",
                              padding: "8px",
                              borderRadius: "5px",
                            }}
                          >
                            {/*  <strong>User:</strong>  */}
                            {question.q1}
                          </div>
                        </div>
                        <div
                          className="generic-response"
                          style={{
                            lineHeight: "1.6",
                            marginTop: "20px",
                            background: "#394896",
                            padding: "8px",
                            borderRadius: "5px",
                          }}
                        >
                          {/* <strong>Agent:</strong>{" "} */}
                          {isLastQuestion &&
                            (!fullResponse ? (
                              <SSEDisplay
                                threadId={question.threadId}
                                docId={question.id}
                                setApiResponse={setApiResponse}
                                setIsLoading={setIsLoading}
                              />
                            ) : (
                              // Using dangerouslySetInnerHTML to inject the HTML
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: formatDescription(fullResponse),
                                }}
                              />
                            ))}
                          {!isLastQuestion && question.response && (
                            <div>
                              {question.response
                                .split(". ")
                                .map((sentence, idx, arr) => (
                                  <p key={idx}>
                                    {sentence +
                                      (idx < arr.length - 1 ? "." : "")}
                                  </p>
                                ))}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/*  <div>
            {showRating && (
              <RatingComponent onSubmitRating={handleRatingSubmit} />
            )}
          </div> */}
              <div>
                <div className="input-container">
                  <form onSubmit={handleSubmit} className="input-container">
                    <textarea
                      ref={textAreaRef}
                      placeholder="Scriverci qui..."
                      onChange={(e) => setNewQ1(e.target.value)}
                      value={newQ1}
                      className="chatbox-style"
                      maxLength="1000"
                      required
                      rows="1"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          handleSubmit(e);
                        }
                      }}
                      onInput={(e) => {
                        if (textAreaRef.current) {
                          textAreaRef.current.style.height = "auto";
                          textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
                        }
                      }}
                    />

                    <button type="submit" className="submit-button">
                      ➡️
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div>
              <div className="proposed-goals-container">
                <div className="header-container">
                  <GoalsBox thread_id={threadId} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      </div>
    </div>
  );
}

export default PostSignup;
