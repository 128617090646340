import React, { useState, useEffect, useRef } from "react";
import { auth, googleProvider, db } from "../config/firebase.js";
import {
  getFirestore,
  query,
  where,
  doc,
  setDoc,
  collection,
  getDocs,
  addDoc,
  getDoc,
  updateDoc, arrayUnion
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Users from "./users.js";
import { useAuth } from "../../src/components/auth-email.js";
import ToggleSwitch from "../components/toggle.js";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
  LineChart,
} from "recharts";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import "..//pages/allCSS.css";
import UserPromptModal from "..//../src/components/userPrompt.js";
import thumbup from "..//../src/thumbup.svg";
import thumbdown from "..//../src/thumbdown.svg";
import rightarrow from "..//../src/right_arrow.svg";

function IncentiveSearchCommercialista() {
  const { currentUser } = useAuth(); // Using a custom hook to get the current user
  const [atecoL3, setAtecoL3] = useState("");
  const [regione, setRegione] = useState("");
  const [firstName, setFirstName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [role, setRole] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [companyEmployees, setCompanyEmployees] = useState("");
  const [companyTurnover, setCompanyTurnover] = useState("");
  const [Descrizione, setDescrizione] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [incentives, setIncentives] = useState("");
  const [documents, setDocuments] = useState([]);
  const [incentivesCount, setIncentivesCount] = useState(0);
  const [totalIncentives, setTotalIncentives] = useState(0);
  const [totalFunding, setTotalFunding] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [incentivesData, setIncentivesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoadingIncentives, setIsLoadingIncentives] = useState(true);
  const [isLoadingFunding, setIsLoadingFunding] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);
  const [cw, setCw] = useState(""); // Empty string as initial value or a default value "0" or "1"
  const [lastDataUpdate, setLastDataUpdate] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);
  const [newpiva, setNewpiva] = useState("");
  const [refreshData, setRefreshData] = useState(false); // State to trigger re-render
  const [dataChangeTrigger, setDataChangeTrigger] = useState(0);
  const [pivas, setPivas] = useState([]);
  const [selectedRegione, setSelectedRegione] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Assume auth is your authentication instance (e.g., from Firebase)
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  const newEventRegioneOptions = [
    "Abruzzo",
    "Aosta",
    "Basilicata",
    "Calabria",
    "Campania",
    "Emilia Romagna",
    "Friuli Venezia Giulia",
    "Lazio",
    "Liguria",
    "Lombardia",
    "Marche",
    "Molise",
    "Piemonte",
    "Puglia",
    "Sardinia",
    "Sicilia",
    "Trentino/Alto Adige/Sudtirol",
    "Toscana",
    "Umbria",
    "Veneto",
  ];

  /*regione*/
  const handleRegionChange = (e) => {
    const selectedOption = e.target.value;

    // This will set the region to Lombardia if selected, or directly to the chosen option otherwise.
    setSelectedRegione(selectedOption);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////
  //// Create upload piva feature and create FB record

  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        try {
          // Reference to the commercialista collection
          const commercialistaCollection = collection(db, "commercialista");

          // Query to fetch PIVA entries where the commercialista field matches the user ID
          const q = query(
            commercialistaCollection,
            where("commercialista", "==", user.uid)
          );
          const snapshot = await getDocs(q);

          // Map over the fetched documents to create an array of PIVA entries
          const pivaList = snapshot.docs.map((doc) => ({
            id: doc.id, // This is the document ID (user ID) that we want to pass
            ...doc.data(),
          }));

          setPivas(pivaList); // Set the state with the fetched PIVA entries
        } catch (error) {
          console.error("Error fetching PIVA data:", error);
        }
      }
    };

    fetchData();
  }, []); // Dependency array includes the trigger

  const updateCommDb = async () => {
    console.log("Button clicked to update CommDb"); // Debug: Confirm when this is called
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      try {
        console.log("Processing PIVA and regione:", newpiva, selectedRegione); // Debug: Check the values being processed
        const pivaArray = newpiva.split(",").map((piva) => piva.trim());

        // Reference to the commercialista collection directly
        const commercialistaCollection = collection(db, "commercialista");

        // Fetch existing PIVA documents to check the count
        const q = query(
          commercialistaCollection,
          where("commercialista", "==", user.uid)
        );
        const snapshot = await getDocs(q);
        const existingPivasCount = snapshot.docs.length;

        // Check if adding new PIVAs exceeds the maximum allowed
        if (existingPivasCount + pivaArray.length > 3) {
          console.error("Too many PIVAs requested, maximum available = 3");
          alert("Too many PIVAs requested, maximum available = 3"); // Alert user
          return; // Stop further execution
        }

        // If within the limit, proceed to add new PIVAs
        for (const piva of pivaArray) {
          const docRef = await addDoc(commercialistaCollection, {
            piva: piva,
            regione: selectedRegione,
            timestamp: new Date(),
            commercialista: user.uid, // Add the user ID to the document
          });

          console.log("Document added with ID:", docRef.id); // Log the document ID

          console.log("Document added, now sending to backend"); // Debug: Confirm step before backend call
          const apiResponse = await sendPivaToBackend(piva, docRef.id);
          console.log("Backend response:", apiResponse); // Debug: Log backend response

          if (apiResponse.status === "success") {
            // Optionally update the document with additional API data
            await setDoc(
              doc(db, "commercialista", docRef.id),
              apiResponse.data,
              { merge: true }
            );
            console.log(
              "Firestore document updated successfully with API data."
            );
          } else {
            throw new Error(
              "API data processing failed: " + apiResponse.message
            );
          }
        }
      } catch (error) {
        console.error(
          "Error updating Firestore or communicating with the backend:",
          error
        );
      }
    } else {
      console.error("No user is signed in.");
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////
  //// Run the api/piva script and save to the collection

  const sendPivaToBackend = async (piva, docId) => {
    console.log("Sending piva to backend:", piva);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    try {
      const payload = { piva, docId };
      const response = await fetch(`${API_BASE_URL}/api/piva`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) throw new Error("Network response was not ok");
      const responseData = await response.json();
      console.log("Backend process triggered successfully:", responseData);
      return responseData; // Ensure this contains the status and data
    } catch (apiError) {
      console.error("API call failed:", apiError);
      return { status: "error", message: apiError.toString() };
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////
  //// Display the aggregate results (all the businesses) and ability to filter for 1 piva

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPivas = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
  
      if (user) {
        const commercialistaCollection = collection(db, "commercialista");
        const q = query(
          commercialistaCollection,
          where("commercialista", "==", user.uid)
        );
  
        try {
          const querySnapshot = await getDocs(q);
          const pivaList = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setPivas(pivaList); // Set the state with the fetched PIVA entries
  
          // Automatically select the first PIVA if available
          if (pivaList.length > 0) {
            handleRowSelect(pivaList[0]); // Call handleRowSelect with the first PIVA
          }
  
          setLoading(false);
        } catch (error) {
          console.error("Error fetching PIVAs:", error);
          setLoading(false);
        }
      }
    };
  
    fetchPivas();
  }, []); // Dependency array includes the trigger
  

  /////////////////////////////////////////////////////////////////////////////////////////////
  /////table selector
  const [selectedPivaData, setSelectedPivaData] = useState(null);
  const [commUserId, setCommUserId] = useState('');
  const [selectedPivaId, setSelectedPivaId] = useState(null);

  const handleRowSelect = (piva) => {
    //console.log("Selected PIVA:", piva);
    setAtecoL3(piva.companyAteco);
    setRegione(piva.regione);
    setCompanySize(piva.companySize);
    setCompanyName(piva.companyName);
    setCompanyTurnover(piva.companyTurnover);
    setCompanyEmployees(piva.companyEmployees);
    fetchIncentivesData(
      piva.companyAteco,
      piva.regione,
      piva.companySize,
      piva.companyTurnover,
      piva.companyEmployees
    );

    // Set selected Piva data
    setSelectedPivaData({
      companyName: piva.companyName,
      companySize: piva.companySize,
      companyTurnover: piva.companyTurnover,
      companyAteco: piva.companyAteco,
      regione: piva.regione,
      piva: piva.piva,
      docName: piva.id 
    });
    //localStorage.setItem("CommuserId", piva.id);
    fetchGoals();
    setSelectedPivaId(piva.id);
  };
  

  const fetchIncentivesData = async (
    atecoL3,
    regione,
    companySize,
    companyTurnover,
    companyEmployees
  ) => {
    const extractBaseCode = (ateco) => {
      const numericalPartMatch = ateco.match(/^(\d{2}\.\d)(\d?)\b/);
      let numericalPart = "";

      if (numericalPartMatch) {
        const beforeDecimal = numericalPartMatch[1];
        const afterDecimal = numericalPartMatch[2];
        numericalPart =
          afterDecimal.length === 0
            ? `${beforeDecimal}0`
            : `${beforeDecimal}${afterDecimal}`;
      }

      return numericalPart;
    };

    const correctedRegione = regione === "Sardinia" ? "Sardegna" : regione;

    const requestBody = {
      codicel3: atecoL3 ? extractBaseCode(atecoL3) : "",
      regione: correctedRegione || "",
      companySize: companySize,
      companyTurnover: companyTurnover,
      companyEmployees: companyEmployees,
      currentuserID: getAuth().currentUser.uid, // Include the user ID here
    };

    //console.log("Sending to server:", requestBody);

    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/incentives`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      let filteredData = await response.json();
      filteredData = filteredData.map((incentive) => ({
        ...incentive,
        filtered: 1,
      }));
      localStorage.setItem("filteredData", JSON.stringify(filteredData));
      //console.log("Incentives data received from server:", filteredData);
    } catch (error) {
      console.error("Error fetching incentives data:", error);
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////
  //// Then repeat the same individual piva search - replace users db with commercialista_users
  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser && currentUser.email) {
        const usersCollectionRef = collection(db, "users");
        const q = query(
          usersCollectionRef,
          where("email", "==", currentUser.email)
        );
        try {
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const userData = querySnapshot.docs[0].data();
            setFirstName(userData.firstName);
          } else {
            console.log("No user data found for email:", currentUser.email);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [currentUser]); // Dependency array includes currentUser to refetch if it changes

  const [filters, setFilters] = useState({
    CW: "",
    Agevolazione: "",
    Fondo_perduto: "",
    Capitale_di_rischio: "",
    Prestito: "",
    Interventi_garanzia: "",
    Anticipo_rimborsabile: "",
    hyperFilter: "",
    strategy: "",
    thumb: "",
  });

  const [usingFiltered, setUsingFiltered] = useState(false);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("filteredData")) || [];
    const filteredIds = storedData
      .filter((incentive) => incentive.filtered === 1)
      .map((incentive) => incentive.ID_Incentivo);

    if (filteredIds.length > 0) {
      fetchIncentiviByIds(filteredIds).then((fetchedIncentives) => {
        setIncentives(fetchedIncentives);
      });
    } else {
      setIncentives([]);
    }
  }, [usingFiltered, filters, lastDataUpdate]);

  const fetchIncentiviByIds = async (ids) => {
    const db = getFirestore();
    const incentiviCollection = collection(db, "incentivi");

    const promises = ids.map((id) => {
      const docRef = doc(incentiviCollection, id);
      return getDoc(docRef);
    });

    try {
      const documentSnapshots = await Promise.all(promises);
      const documents = documentSnapshots
        .map((docSnapshot) =>
          docSnapshot.exists()
            ? { id: docSnapshot.id, ...docSnapshot.data() }
            : null
        )
        .filter((doc) => doc !== null); // Filtering out null values if document doesn't exist

      // Sort documents by Data_apertura in descending order
      documents.sort((a, b) => {
        const dateA = new Date(a.Data_apertura);
        const dateB = new Date(b.Data_apertura);
        return dateB - dateA; // descending order
      });

      return documents;
    } catch (error) {
      console.error("Error fetching documents by IDs:", error);
      return []; // Returning an empty array on error ensures the UI can handle this gracefully
    }
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [field]: value };
      const currentFilteredData =
        JSON.parse(localStorage.getItem("filteredData")) || [];
      const updatedIncentives = currentFilteredData.map((incentive) => {
        const shouldFilter = Object.keys(updatedFilters).every(
          (key) =>
            updatedFilters[key] === "" || incentive[key] === updatedFilters[key]
        );

        return {
          ...incentive,
          filtered: shouldFilter ? 1 : 0,
        };
      });

      localStorage.setItem("filteredData", JSON.stringify(updatedIncentives));
      setUsingFiltered((u) => !u); // Toggle to force re-render
      return updatedFilters;
    });
  };

  const [filteredCount, setFilteredCount] = useState(0); // State to store the count

  useEffect(() => {
    //console.log("Effect triggered by filters or data update");

    const storedData = JSON.parse(localStorage.getItem("filteredData")) || [];
    const filteredIds = storedData
      .filter((incentive) => incentive.filtered === 1)
      .map((incentive) => incentive.ID_Incentivo);

    setFilteredCount(filteredIds.length);
    //console.log("Updated filtered count:", filteredIds.length);

    if (filteredIds.length > 0) {
      fetchIncentiviByIds(filteredIds).then((fetchedIncentives) => {
        setIncentives(fetchedIncentives);
        //console.log("Incentives updated based on filtered IDs");
      });
    } else {
      setIncentives([]);
      //console.log("No filtered incentives to display");
    }
  }, [filters, lastDataUpdate]);

  ///////////////////////////////////////
  ///////reset filters when page mounts

  const resetFilters = () => {
    // Fetch the current filtered data from local storage
    const storedData = JSON.parse(localStorage.getItem("filteredData")) || [];

    // Set all 'filtered' properties to 1
    const updatedData = storedData.map((incentive) => ({
      ...incentive,
      filtered: 1, // Resetting all to visible
    }));

    // Update local storage with the modified data
    localStorage.setItem("filteredData", JSON.stringify(updatedData));

    // Update the state used to display incentives
    fetchIncentiviByIds(
      updatedData
        .filter((incentive) => incentive.filtered === 1)
        .map((incentive) => incentive.ID_Incentivo)
    ).then((fetchedIncentives) => {
      setIncentives(fetchedIncentives);
    });

    // Optionally, reset any filter states if being used to track current filters
    setFilters({
      CW: "",
      Agevolazione: "",
      Fondo_perduto: "",
      Capitale_di_rischio: "",
      Prestito: "",
      Interventi_garanzia: "",
      Anticipo_rimborsabile: "",
      hyperFilter: "",
      strategy: "",
      thumb: "",
    });
  };

  useEffect(() => {
    // Call resetFilters on the initial load of the component
    resetFilters();
  }, []);

  const sendSearch = async (Descrizione, setLastDataUpdate) => {
    setIsLoading(true); // Start loading
    //console.log("Start sendSearch with Description:", Descrizione);

    const storedData = localStorage.getItem("filteredData");
    let incentives = storedData ? JSON.parse(storedData) : [];
    //console.log("Loaded incentives from localStorage:", incentives);

    const ids = incentives
      .filter((incentive) => incentive.filtered === 1)
      .map((incentive) => incentive.ID_Incentivo);
    //console.log("Filtered IDs to send:", ids);

    if (!currentUser || ids.length === 0) {
      console.error(
        "User information is missing or no incentive IDs available."
      );
      return;
    }

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${API_BASE_URL}/api/sendSearch`;
    const requestBody = {
      ids: ids,
      Descrizione: Descrizione,
    };

    //console.log("Sending request to server with body:", requestBody);

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      //console.log("Response data received:", responseData);

      if (
        !responseData.incentivi_info ||
        responseData.incentivi_info.length === 0
      ) {
        //console.log("Nessun incentivo trovato");
        alert("Nessun incentivo trovato"); // Alert user if no incentives are returned from the server
      } else {
        const returnedIDs = responseData.incentivi_info.map(
          (item) => item.ID_Incentivo
        );
        //console.log("Returned IDs from server:", returnedIDs);

        incentives = incentives.map((incentive) => ({
          ...incentive,
          filtered: returnedIDs.includes(incentive.ID_Incentivo) ? 1 : 0,
        }));
        //console.log("Updated incentives after response:", incentives);
        localStorage.setItem("filteredData", JSON.stringify(incentives));
        setLastDataUpdate(Date.now()); // Update state to trigger re-render
      }
    } catch (error) {
      console.error("Error sending search to server:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const formatDescription = (desc) => {
    if (typeof desc === "string") {
      desc = desc.trim(); // Trim whitespace at the start and end

      // Remove everything before and including the first colon on the first line
      desc = desc.replace(/^[^:]*:\s*/, "");

      // Normalize new lines and handle the initial bullet point
      let lines = desc.split(/\n+/); // Split the description into lines
      lines = lines.reduce((filteredLines, line, index) => {
        line = line.trim(); // Trim each line to remove leading/trailing whitespace

        // Skip empty lines to avoid adding extra spaces
        if (line === "") {
          return filteredLines;
        }

        // Handle numbered lines to not start with a bullet
        if (/^\d+\./.test(line)) {
          filteredLines.push(line); // Return the line as is if it starts with a number and a period
        } else if (index > 0 && filteredLines.length > 0) {
          // Apply bullets to lines that are not the first line and not after an empty line
          filteredLines.push("• " + line);
        } else {
          filteredLines.push(line); // First line or any line not fitting the above criteria gets no bullet
        }

        return filteredLines;
      }, []);

      // Join the lines back together, inserting a newline between each
      return lines.join("\n");
    }
    return "No description available"; // Return a default message if no description is provided
  };

  const getCategoryClass = (category) => {
    const categoryClasses = {
      "Crisi d'impresa": "crisi-impresa",
      Digitalizzazione: "digitalizzazione",
      "Imprenditoria femminile": "imprenditoria-femminile",
      "Innovazione e ricerca": "Innovazione-ricerca",
      "Sostegno investimenti": "Sostegno-investimenti",
      "Start up/Sviluppo d'impresa": "Startup-impresa",
      "Crisi d'impresa": "Crisi-impresa",
      "Inclusione sociale": "Inclusione-sociale",
      "Imprenditoria giovanile": "Imprenditoria-giovanile",
      Internazionalizzazione: "Internazionalizzazione",
      "Transizione ecologica": "Transizione-ecologica",
      // Add more mappings here...
    };
    return categoryClasses[category] || "default-category"; // Fallback class
  };

  const formatDate = (dateString) => {
    return dateString
      ? dateString.split("T")[0]
      : "Aperto fino a nuova communicazione"; // Default to 'N/A' if dateString is undefined
  };

  const formatCurrency = (value) => {
    if (value) {
      const millions = Number(value) / 1000000;
      return `€${millions.toFixed(2)}M`;
    }
    return "N/A"; // Default case if the value is not provided
  };

  const isYear2024 = (dateString) => {
    if (!dateString) return false; // Guard clause for undefined or empty input
    const year = new Date(dateString.split("T")[0]).getFullYear(); // Get the year from the date
    return year === 2024;
  };

  const placeholders = [
    "SMART&START",
    "PNRR",
    "CREDITO",
    "INTERNAZIONALE",
    "INFRASTRUTTURE",
    "TASSO ZERO",
    "AGEVOLAZIONI",
  ];
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholders[0]);
  const [description, setDescription] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      // Update the placeholder text by cycling through the placeholders array
      currentIndex = (currentIndex + 1) % placeholders.length;
      setCurrentPlaceholder(placeholders[currentIndex]);
    }, 1000); // Change placeholder every 2000 milliseconds (2 seconds)

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  //////////////////////////////////////////////////////////////////////////////
  /////////////////
  /// Nav to ThreadUnic
  const navigate = useNavigate();
  const [threadId, setThreadId] = useState("");
  const [currentThreadId, setCurrentThreadId] = useState(null);

  const startNewConversation = async (
    atecoL3,
    regione,
    assistant,
    incentiveId,
    firstName,
    companyName,
    companySize,
    companyEmployees,
    companyTurnover
  ) => {
    if (!selectedPivaData) {
      alert("Please select a piva before starting a new conversation.");
      return;
    }
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const userMessage = `Ciao, e questo incentivo buona per nostra azienda? Io sono ${firstName} e l'azienda si chiama ${companyName}. Siamo in ${regione} con ateco: ${atecoL3}. Siamo un ${companySize} con ${companyEmployees} dipendenti e turnover annuale di €${companyTurnover}.`;
      const response = await fetch(`${API_BASE_URL}/api/start_new_convo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: userMessage }),
      });
      //console.log("API Base URL:", API_BASE_URL);
      console.log("firstName:", firstName);
      console.log("companyName:", companyName);
      console.log("firstName:", regione);
      console.log("message:", userMessage);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      //console.log("Response data:", data); // Log the entire response data
      localStorage.setItem("currentThreadId", data.thread_id);
      //console.log("Assistant parameter:", assistant);
      //console.log("Stored thread_id in localStorage:", data.thread_id);

      if (data.thread_id && assistant) {
        //console.log("New thread ID:", data.thread_id, "assistant:", assistant);
        const title = `Discussion on ATECO ${atecoL3} in ${regione}`;
        window.scrollTo(0, 0);
        await fetchGoals();
        // Navigate to the threadUnic page with the thread ID and assistant ID in the URL
        navigate(`/threadUnic/${assistant}/${data.thread_id}/${incentiveId}`, {
          state: { title: title },
        });
      } else {
        console.error(
          "Thread ID or Assistant ID was not returned from the server."
        );
      }
    } catch (error) {
      console.error("Error starting a new conversation:", error);
    }
  };

  const uniquePivas = new Set(pivas.map((piva) => piva.piva));

  const updateThumbs = async (incentiveId, thumbsValue) => {
    if (!currentUser || !currentUser.uid) {
      console.error("User is not authenticated or UID is missing");
      return; // Do nothing if user is not authenticated
    }

    console.log("Sending thumbs update:", {
      userId: currentUser.uid, // Use currentUser.uid instead of currentUser.id
      incentiveId,
      thumbsValue,
    }); // Log the values being sent

    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/updatethumbs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: currentUser.uid, // Ensure this is the actual user UID
          incentiveId, // This should be the actual incentive ID
          thumbsValue, // This should be 'Y' or 'N'
        }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("Update successful:", data.message);
      } else {
        console.error("Error updating thumbs:", data.error);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  const nextSectionRef = useRef(null);
  const scrollToNextSection = () => {
    if (nextSectionRef.current) {
      nextSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };




  ///////////////////goals

  const [goalInput, setGoalInput] = useState('');
  const [isGoalInputVisible, setIsGoalInputVisible] = useState(false);

  const handleGoalSubmit = async (event) => {
    if (event.key === 'Enter' && goalInput.trim()) {
      try {
        // Log the document name to check if it's set correctly
        const docName = selectedPivaData.docName;
        //console.log("Using docName for goals document:", docName);
  
        // Check if docName is valid
        if (!docName) {
          console.error("Document name is not set. Cannot add goal.");
          return; // Early exit if docName is not valid
        }
  
        const goalsCollectionRef = collection(db, "goals"); // Reference the goals collection
        const goalDocRef = doc(goalsCollectionRef, docName); // Use the selected PIVA's doc name
  
        // Check if the document exists
        const goalDoc = await getDoc(goalDocRef);
        if (goalDoc.exists()) {
          // Document exists, add goal to the goals array
          await updateDoc(goalDocRef, {
            goals: arrayUnion(goalInput)
          });
        } else {
          // Document does not exist, create a new one with the goals array
          await setDoc(goalDocRef, {
            goals: [goalInput] // Initialize with the first goal
          });
        }
  
        //console.log("Goal added:", goalInput);
        setGoalInput(''); // Clear the input after submission
        setIsGoalInputVisible(false); // Hide the input after submission
      } catch (error) {
        console.error("Error adding goal:", error);
      }
    }
  };
  
  const [currentGoals, setCurrentGoals] = useState([]); // State for holding the current goals

  const fetchGoals = async () => {
    try {
      const goalsCollectionRef = collection(db, "goals");
      const goalDocRef = doc(goalsCollectionRef, selectedPivaData.docName); // Use the selected PIVA's ID
  
      // Fetch the document for goals
      const goalDoc = await getDoc(goalDocRef);
      if (goalDoc.exists()) {
        const goalData = goalDoc.data();
        // Set the goals in state
        setCurrentGoals(goalData.goals || []); // Initialize to an empty array if no goals exist
        //console.log("Fetched Goals:", goalData.goals);
      } else {
        //console.log("No goals found for this document.");
        setCurrentGoals([]); // Set to an empty array if no document exists
      }
    } catch (error) {
      console.error("Error fetching goals:", error);
    }
  };


  const extractBase = (ateco) => {
    const numericalPartMatch = ateco.match(/^(\d{2}\.\d)(\d?)\b/);
    let numericalPart = "";

    if (numericalPartMatch) {
      const beforeDecimal = numericalPartMatch[1];
      const afterDecimal = numericalPartMatch[2];
      numericalPart =
        afterDecimal.length === 0
          ? `${beforeDecimal}0`
          : `${beforeDecimal}${afterDecimal}`;
    }

    return numericalPart;
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="searchy-container-comm">
      {!isAuthenticated && <UserPromptModal />}
      <h1 className="incentivy-container-comm2">Professionista Portal </h1>
      <div>
        <div className="piva-container-comm">
          <button
            onClick={toggleVisibility}
            style={{ marginBottom: "10px" }}
            className="logout-button"
          >
            {isVisible ? "-" : "+"} Partita IVA
          </button>

          {isVisible && (
            <div>
              <div
                className="container-searchi"
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #ccc",
                  marginBottom: "10px",
                  padding: "5px",
                }}
              >
                <label style={{ marginRight: "10px" }}>Partita IVA:</label>
                <input
                  className="input-field-searchi boxes"
                  type="text"
                  placeholder="12345678901"
                  value={newpiva}
                  onChange={(e) => setNewpiva(e.target.value)}
                  style={{ flex: 1, placeholderColor: "grey" }} // Placeholder styling should be handled in CSS
                />
              </div>

              <div
                className="container-searchi"
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #ccc",
                  padding: "5px",
                }}
              >
                <label style={{ marginRight: "10px" }}>Regione:</label>
                <select
                  className="select-dropdown-searchij"
                  value={selectedRegione}
                  onChange={handleRegionChange}
                  style={{ flex: 1 }}
                >
                  <option value="defaultRole">Sceglie un regione</option>
                  {newEventRegioneOptions.map((regione, index) => (
                    <option key={index} value={regione}>
                      {regione}
                    </option>
                  ))}
                </select>
              </div>

              <button
                type="button"
                onClick={updateCommDb}
                style={{
                  background: "#092f7a",
                  color: "white",
                  border: "1px solid white",
                  borderRadius: "5px",
                  padding: "10px 20px",
                  cursor: "pointer",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                VAI
              </button>
            </div>
          )}
        </div>
        <div className="agent2-containers-comm">
          <div className="incentivy-container-comm">
          <h1>Portfolio: {uniquePivas.size} imprese</h1>
{pivas.length > 0 ? (
  <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid white" }}>
    <thead>
      <tr>
        <th
          style={{
            color: "white",
            border: "1px solid white",
            padding: "8px",
            textAlign: "center",
          }}
        >
          PIVA
        </th>
        <th
          style={{
            color: "white",
            border: "1px solid white",
            padding: "8px",
            textAlign: "center",
          }}
        >
          Nome
        </th>
        <th
          style={{
            color: "white",
            border: "1px solid white",
            padding: "8px",
            textAlign: "center",
          }}
        >
          Regione
        </th>
        <th
          style={{
            color: "white",
            border: "1px solid white",
            padding: "8px",
            textAlign: "center",
          }}
        >
          Size
        </th>
        <th
          style={{
            color: "white",
            border: "1px solid white",
            padding: "8px",
            textAlign: "center",
          }}
        >
          Ateco
        </th>
      </tr>
    </thead>
    <tbody>
      {pivas.map((piva) => (
        <tr 
        key={piva.id}
      onClick={() => handleRowSelect(piva)}
      style={{
        border: `2px solid ${selectedPivaId === piva.id ? '#e0e0e0' : '#e0e0e0'}`, // Set the border color based on selection
        backgroundColor: selectedPivaId === piva.id ? '#FF5758' : '#e0e0e0',
        cursor: "pointer",
          }}
        >
          <td
            style={{
              color: "white",
              border: "1px solid black",
              padding: "8px",
            }}
          >
            {piva.piva}
          </td>
          <td
            style={{
              color: "white",
              border: "1px solid black",
              padding: "8px",
            }}
          >
            {piva.companyName}
          </td>
          <td
            style={{
              color: "white",
              border: "1px solid black",
              padding: "8px",
            }}
          >
            {piva.regione}
          </td>
          <td
            style={{
              color: "white",
              border: "1px solid black",
              padding: "8px",
            }}
          >
            {piva.companySize}
          </td>
          <td
            style={{
              color: "white",
              border: "1px solid black",
              padding: "8px",
            }}
          >
            {piva.companyAteco}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
) : (
  <div>No PIVA records found.</div>
)}
            <h3 style={{marginBottom: "0px", marginTop: "20px"}}></h3>
            {selectedPivaData && (
              
              <div
                style={{
                  fontSize: "18px",
                  padding: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  background: "#092f7a",
                  color: "white",
                }}
              >
                
                 {/* {selectedPivaData.companyName} - {selectedPivaData.piva} */}
                
                <div>
                <div style={{ marginTop: "2px", marginBottom: "15px", padding: "5px", border: "1px solid #ccc", borderRadius: "5px", backgroundColor: "#f9f9f9" }}>
                <div style={{ color: "#333", marginBottom: "10px", fontSize: "25px", fontWeight: "bold" }}>Current Goals</div>
                  {currentGoals.length > 0 ? (
                    <ul style={{ listStyleType: "none", paddingLeft: "0px", color: "#555" }}> {/* Removed bullets */}
                      {currentGoals.map((goal, index) => (
                        <li key={index} style={{ marginBottom: "5px" }}>{goal}</li> 
                      ))}
                    </ul>
                  ) : (
                    <p style={{ color: "#777", fontStyle: "italic" }}>No goals added yet.</p>
                  )}
                </div>

                <button
                    type="button"
                    onClick={() => setIsGoalInputVisible(true)} // Show the input field when clicked
                    className="logout-button"
                    style={{ marginRight: "10px", fontSize: "16px" }}
                  >
                    + Goals
                  </button>

                  {isGoalInputVisible && (
                    <input
                      type="text"
                      value={goalInput}
                      onChange={(e) => setGoalInput(e.target.value)}
                      onKeyDown={handleGoalSubmit} // Handle submission on Enter key
                      placeholder="Enter your goal"
                      style={{ marginLeft: "10px" }}
                    />
                  )}
                  <button
                    type="button"
                    onClick={resetFilters}
                    className="logout-button"
                    style={{ fontSize: "16px" }}
                  >
                    Vedi Bandi
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="agent2-containers-comm">
          <div ref={nextSectionRef} className="incentivy-container-commer">
            <h1
              style={{
                margin: "10px 0 20px 0",
                color: "#092f7a",
                textAlign: "center",
                color: "white",
              }}
            >
              <div>
                {selectedPivaData && (
                  <div>
                    <p>
                      {selectedPivaData.companyName} ha {filteredCount}{" "}
                      incentivi dedicati
                    </p>
                    <div></div>
                  </div>
                )}

                <div style={{ fontSize: "20px", paddingTop: "20px" }}>
                  &rarr; Stiamo filtrando per Regione{" "}
                  <span style={{ color: "#FF5758" }}>({regione})</span>, Ateco{" "}
                  <span style={{ color: "#FF5758" }}> ({extractBase(atecoL3)})</span> e
                  Dimensione{" "}
                  <span style={{ color: "#FF5758" }}>({companySize})</span>.
                </div>
              </div>
            </h1>
            <div
              style={{ fontSize: "20px", color: "white", marginTop: "25px" }}
            >
            </div>
            <div
              style={{
                display: "flex", // Enables flexbox
                flexDirection: "column", // Stacks children vertically
                padding: "20px",
                color: "white",
                alignItems: "center", // Aligns children at the start of the flex container
              }}
            >
              <div className="toggle-switch-container">
                <ToggleSwitch
                  label="HyperFilter"
                  onChange={(value) => handleFilterChange("hyperFilter", value)}
                />
                <span className="info-icon">
                  i
                  <span className="tooltip-texty">
                    Basato su AI. Include comune
                  </span>
                </span>
              </div>

              <div className="toggle-switch-container">
                <ToggleSwitch
                  label="Strategia"
                  onChange={(value) => handleFilterChange("strategy", value)}
                />
                <span className="info-icon">
                  i
                  <span className="tooltip-texty">
                    In base ai tuoi obiettivi
                  </span>
                </span>
              </div>

              <div className="toggle-switch-container">
                <ToggleSwitch
                  label="Favourites"
                  onChange={(value) => handleFilterChange("thumb", value)}
                />
                <span className="info-icon">
                  i<span className="tooltip-texty">I tuoi preferiti</span>
                </span>
              </div>

              <div className="toggle-switch-container">
                <ToggleSwitch
                  label="Settimana Corrente"
                  onChange={(value) => handleFilterChange("CW", value)}
                />
                <span className="info-icon">
                  i
                  <span className="tooltip-texty">Novità questa settimana</span>
                </span>
              </div>

              <div className="toggle-switch-container">
                <ToggleSwitch
                  label="Agevolazione"
                  onChange={(value) =>
                    handleFilterChange("Agevolazione", value)
                  }
                />
                <span className="info-icon">
                  i<span className="tooltip-texty">Sgravi fiscali</span>
                </span>
              </div>

              <div className="toggle-switch-container">
                <ToggleSwitch
                  label="Fondo Perduto"
                  onChange={(value) =>
                    handleFilterChange("Fondo_perduto", value)
                  }
                />
                <span className="info-icon">
                  i
                  <span className="tooltip-texty">
                    Non richiedono il rimborso
                  </span>
                </span>
              </div>

              <div className="toggle-switch-container">
                <ToggleSwitch
                  label="Capitale di rischio"
                  onChange={(value) =>
                    handleFilterChange("Capitale_di_rischio", value)
                  }
                />
                <span className="info-icon">
                  i<span className="tooltip-texty">Investimento in equity</span>
                </span>
              </div>

              <div className="toggle-switch-container">
                <ToggleSwitch
                  label="Prestito"
                  onChange={(value) => handleFilterChange("Prestito", value)}
                />
                <span className="info-icon">
                  i
                  <span className="tooltip-texty">
                    Devono essere restituiti
                  </span>
                </span>
              </div>

              <div className="toggle-switch-container">
                <ToggleSwitch
                  label="Interventi Garanzia"
                  onChange={(value) =>
                    handleFilterChange("Interventi_garanzia", value)
                  }
                />
                <span className="info-icon">
                  i
                  <span className="tooltip-texty">
                    Facilitare l'accesso al credito
                  </span>
                </span>
              </div>

              <div className="toggle-switch-container">
                <ToggleSwitch
                  label="Anticipo rimborsabile"
                  onChange={(value) =>
                    handleFilterChange("Anticipo_rimborsabile", value)
                  }
                />
                <span className="info-icon">
                  i
                  <span className="tooltip-texty">
                    Ricevere un'importo di denaro in anticipo, che dovrà essere
                    successivamente rimborsato
                  </span>
                </span>
              </div>
            </div>
            <button className="button-searchi" onClick={resetFilters}>
              Reset Filters
            </button>
          </div>
        </div>

        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
            fontSize: "16px",
          }}
        >
          <div
            className="incentivy-container-comm"
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              color: "#092f7a",
              padding: "10px",
              textAlign: "center",
            }}
          >
            <h1 style={{ fontSize: "20px", marginTop: "20px", color: "white" }}>
              Keywords Ricerca
            </h1>
            <div>
              <input
                type="text"
                placeholder={currentPlaceholder}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                  width: "300px",
                  marginRight: "10px",
                  height: "40px",
                  fontSize: "18px",
                }}
              />
              <button
                className="button-stylish"
                onClick={() => sendSearch(description, setLastDataUpdate)}
                disabled={isLoading} // Disable button while loading
              >
                VAI
              </button>
              {isLoading && <div className="spinner-sendsearch"></div>}
            </div>
          </div>
          <div>
            <div style={{ textAlign: "left", fontSize: "16px" }}>
              <div>
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                {/* {responseMessage && formatResponseMessage(responseMessage)} */}
              </div>
            </div>
            <div>
              <div className="incentivy-container-comm3">
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                <h1
                  style={{
                    marginTop: "20px",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Incentivi Dedicati
                </h1>
                {loading ? (
                  <p>Loading...</p> // You can replace this with a spinner or any loading UI
                ) : incentives.length > 0 ? (
                  <ul style={{ padding: "10px", listStyleType: "none" }}>
                    {incentives.map((doc, index) => (
                      <li
                        key={index}
                        style={{
                          marginBottom: "20px",
                          border: "1px solid #ccc",
                          padding: "20px",
                          borderRadius: "8px",
                          boxShadow:
                            "0 4px 6px rgba(0,0,0,0.1), 5px 5px 5px #FF5758",
                          backgroundColor: "#fff", // adds a clean white background
                          lineHeight: "2rem",
                          fontSize: "20px",
                          position: "relative", // Needed for absolute positioning of the arrow
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "2px",
                            right: "10px",
                            display: "flex",
                            alignItems: "center",
                            margin: "10px 0 10px 0",
                          }}
                        >
                          {/*  {isYear2024(doc.Data_apertura) && (
                          <button
                            className="button-stylishy"
                            onClick={() => {
                              //console.log("Document data:", doc); // Log the entire document
                              const assistant = doc.AssistantID; // Rename AssistantID to assistant
                              startNewConversation(
                                atecoL3,
                                regione,
                                assistant,
                                doc.ID_Incentivo,
                                firstName,
                                companyName,
                                companySize,
                                companyEmployees,
                                companyTurnover
                              );
                            }}
                            style={{ cursor: "pointer", color: "#FF5758" }}
                          >
                            NUOVO
                          </button>
                        )} */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            <button
                              onClick={() =>
                                updateThumbs(doc.ID_Incentivo, "Y")
                              }
                              style={{
                                marginRight: "10px",
                                cursor: "pointer",
                                backgroundColor: "white",
                                border: "none",
                                borderRadius: "4px",
                                fontSize: "25px",
                              }}
                            >
                              <img
                                src={thumbup} // Path to your SVG file
                                alt="Thumbs Up"
                                style={{ width: "30px", height: "30px" }} // Adjust width/height
                              />
                            </button>
                            <button
                              onClick={() =>
                                updateThumbs(doc.ID_Incentivo, "N")
                              }
                              style={{
                                cursor: "pointer",
                                backgroundColor: "white",
                                border: "none",
                                borderRadius: "4px",
                                fontSize: "25px",
                              }}
                            >
                              <img
                                src={thumbdown} // Path to your SVG file
                                alt="Thumbs Down"
                                style={{ width: "30px", height: "30px" }} // Adjust width/height
                              />
                            </button>
                          </div>
                          <button
                            className="button-stylish"
                            onClick={() => {
                              //console.log("Document data:", doc); // Log the entire document
                              const assistant = doc.AssistantID; // Rename AssistantID to assistant
                              startNewConversation(
                                atecoL3,
                                regione,
                                assistant,
                                doc.ID_Incentivo,
                                firstName,
                                companyName,
                                companySize,
                                companyEmployees,
                                companyTurnover
                              );
                            }}
                            style={{ cursor: "pointer", fontSize: "35px" }}
                          >
                            <img
                              src={rightarrow} // Path to your SVG file
                              alt="navigate to criteria page"
                              style={{ width: "30px", height: "30px" }} // Adjust width/height
                            />
                          </button>
                        </div>
                        <h2 style={{ marginTop: "60px" }}>{doc.Titolo}</h2>
                        <strong>
                          {doc.KeyBenefit} <br />
                        </strong>
                        <div style={{ marginTop: "10px" }}>
                          <strong>Apertura:</strong>{" "}
                          {formatDate(doc.Data_apertura)}{" "}
                          <strong>Chiusura:</strong>{" "}
                          {formatDate(doc.Data_chiusura)}
                        </div>

                        <div>
                          {doc.Obiettivo_Finalita.map((goal) => (
                            <span
                              key={goal}
                              className={`category-box ${getCategoryClass(
                                goal
                              )}`}
                              style={{ marginTop: "10px" }}
                            >
                              {goal}
                            </span>
                          ))}
                        </div>
                        <pre className="description-box">
                          {formatDescription(doc.Summary)}
                        </pre>
                        {/*   <br />
                      <button
                        className="button-stylish"
                        onClick={() =>
                          startNewConversation(
                            atecoL3,
                            regione,
                            doc.assistant
                          )
                        }
                      >
                        Scopri di più e candidati
                      </button>
                      <br /><br /> */}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>Aggiungi KEYWORDS</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncentiveSearchCommercialista;
