import "..//pages/allCSS.css";
import React, { useState, useEffect } from "react";
import { db, auth } from "../config/firebase.js";
import { doc, getDoc } from "firebase/firestore";
import { useCart } from "./cartContext.js";
import { useNavigate } from "react-router-dom";
import { loadGtag } from "..//components/gtag.js";

const SubscriptionPlans = () => {
  const [showThanks, setShowThanks] = useState(false);
  const navigate = useNavigate();
  const handleCheckout = () => {
    // Check if gtag is loaded and defined
    if (typeof window.gtag === "function") {
      // Send the apply event to Google Analytics
      window.gtag("event", "productSale", {
        value: 1,
      });
    } else {
      console.error("gtag is not defined or not loaded yet");
    }

    // Show the "Thanks" message instead of navigating right away
    setShowThanks(true);
  };

  const handleThanksButtonClick = () => {
    // Navigate to the home page after clicking the "Thanks" button
    navigate("/search");
  };

  const [isGtagLoaded, setIsGtagLoaded] = useState(false);

  useEffect(() => {
    // Load gtag when the component mounts and set the state when it's ready
    loadGtag()
      .then(() => {
        setIsGtagLoaded(true);
      })
      .catch((error) => {
        console.error("Error loading gtag:", error);
      });
  }, []);


  const sendContactEmail = async () => {
    if (isGtagLoaded && typeof window.gtag === 'function') {
      // Send the signup event to Google Analytics
      window.gtag('event', 'apply', {
        value: 1,
      });
    } else {
      console.error('gtag is not defined or not loaded yet');
    }

    const user = auth.currentUser;
    if (!user) {
      console.error('User is not authenticated.');
      return;
    }

    const userEmail = user.email;

    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/contactus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sender_email: userEmail, incentive_id: 'Pro Service Interest' }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result.message);

        // Show the "Thanks" message after successful submission
        setShowThanks(true);
      } else {
        const error = await response.json();
        console.error('Error sending email:', error.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };






  return (
    <div className="subscription-container">
      <header className="subscription-header">
        <title>Abbonamenti per ogni fase</title>
        <h1>Abbonamenti per ogni fase</h1>
        <p>
          Esplora i nostri piani tecnologici, ciascuno progettato per migliorare
          il processo di richiesta di sovvenzioni con software potenti e
          supporto esperto.
        </p>
      </header>
      <section className="subscription-piani">

      <div className="subscription-piano subscription-essenziale">
          <h2>Free</h2>
          <p className="subscription-prezzo">
            €0/mese
          </p>
          <p className="subscription-subject">
          Esplorare il nostro piano gratuito che offre la ricerca tra oltre 500 incentivi
             per aiutare le
            startup e le piccole imprese a cogliere ogni nuova opportunità.
          </p>
          <ul>
            <li className="subscription-tick">
              <i className="fas fa-check"></i> Cercare per incentivi
            </li>
            <li className="subscription-tick">
              <i className="fas fa-check"></i> Personalizzato per regione, ateco & misura
            </li>
          </ul>

          <div>
            {showThanks ? (
              <div className="thanks-message">
                <span role="img" aria-label="thumbs-up">
                  👍
                </span>{" "}
                Grazie per il tuo interesse!
                <button
                  onClick={handleThanksButtonClick}
                  className="subscription-button"
                >
                  Login e inziamo cercare!
                </button>
              </div>
            ) : (
              <button onClick={handleCheckout} className="subscription-button">
                Iscriviti Gratis
              </button>
            )}
          </div>
        </div>

        <div className="subscription-piano subscription-essenziale">
          <h2>Personalizzato</h2>
          <p className="subscription-prezzo">
            €0 <span>€15</span> /mese
          </p>
          <p className="subscription-subject">
          Non perdere nessuna opportunità: il nostro piano Personalizzato per imprese offre pianificazione delle sovvenzioni, hyper-personalizzato "bandi matching", 
          verifica dei criteri verificati, e corrispondenza per ogni nuova opportunità.
          </p>
          <ul>
          <li className="subscription-tick">
              <i className="fas fa-check"></i> Configurazione strategica del
              piano di sovvenzioni personalizzato proposta dal IA
            </li>
            <li className="subscription-tick">
              <i className="fas fa-check"></i> Hyper Personalizzato Bando Matching con sovvenzioni
              alimentato da AI
            </li>
            <li className="subscription-tick">
              <i className="fas fa-check"></i> Verificare l'applicabilità con l'Agente IA
            </li>
            <li className="subscription-tick">
              <i className="fas fa-check"></i> Newsletter e Alerts per non
              perdere nulla
            </li>
          </ul>

          <div>
            {showThanks ? (
              <div className="thanks-message">
                <span role="img" aria-label="thumbs-up">
                  👍
                </span>{" "}
                Grazie per il tuo acquisto!
                <button
                  onClick={handleThanksButtonClick}
                  className="subscription-button"
                >
                  Iniziamo Cercare!
                </button>
              </div>
            ) : (
              <button onClick={handleCheckout} className="subscription-button">
                Iscriviti Gratis
              </button>
            )}
          </div>
        </div>
        <div className="subscription-piano subscription-completo">
          <h2>Completo</h2>
          <p className="subscription-prezzo">
            €75 <span>€150</span> /mese
          </p>
          <p className="subscription-subject">
          Candidarsi subito: il nostro piano Completo e perfetto per imprese. E offre Tutte le funzionalità del piano
          Personalizzato ma anche l'opportunità mensili di candidatura e supporto
            avanzato AI per rafforzare i tuoi sforzi di sovvenzione
            indipendenti.
          </p>
          <ul>
            <li className="subscription-tick">
              <i className="fas fa-check"></i> Tutte le funzionalità del piano
              Personalizzato
            </li>
            <li className="subscription-tick">
              <i className="fas fa-check"></i> Accesso a un mercato di professionisti per candidarsi
            </li>
            <li className="subscription-tick">
              <i className="fas fa-check"></i> 1 generazioni di pre-applicazione
              al mese
            </li>
            <li className="subscription-tick">
              <i className="fas fa-check"></i> Note: ogni richiesta aggiuntiva
              di pre-applicazione a un prezzo di 100€
            </li>
          </ul>
          <a href="#" className="subscription-button">
            Abbonati Ora
          </a>
        </div>
        <div className="subscription-piano subscription-ultra">
          <h2>Pro</h2>
          <p className="subscription-prezzo">
            €229 <span>€279</span> /mese
          </p>
          <p className="subscription-subject">
            {" "}
            Per i commercialisti e professionisti: ideato per i richiedenti abituali di
            finanziamenti e partner strategici come incubatori, avvocati e
            consulenti che necessitano di supporto completo e maggiore capacità.
            Opportunità mensili di candidatura e supporto umano e
            avanzato AI per rafforzare i tuoi sforzi di sovvenzione
            indipendenti.
          </p>
          <ul>
            <li className="subscription-tick">
              <i className="fas fa-check"></i> Capacità AI illimitate
            </li>
            <li className="subscription-tick">
              <i className="fas fa-check"></i> Gestione Multi-Aziendale
            </li>
            <li className="subscription-tick">
              <i className="fas fa-check"></i> Account Manager dedicato
            </li>
            <li className="subscription-tick">
              <i className="fas fa-check"></i> Configurazione strategica del
              piano di sovvenzioni annuale personalizzato per ogni azienda con i
              nostri esperti
            </li>
          </ul>
          <div>
            {showThanks ? (
              <div className="thanks-message">
                <span role="img" aria-label="thumbs-up">
                  👍
                </span>{" "}
                Grazie! Ti contattiamo fra 24 ore.
                <button
                  onClick={handleThanksButtonClick}
                  className="subscription-button"
                >
                  Torna Cercare
                </button>
              </div>
            ) : (
              <button onClick={sendContactEmail} className="subscription-button">
                Parla con Noi!
              </button>
            )}
          </div>
        </div>
      </section>
      <footer className="subscription-footer">
        <p>
          Unisciti a oltre 2500+ aziende che stanno già crescendo con
          Incentivato.it
        </p>
        <a href="#" className="subscription-button">
          Inizia subito
        </a>
      </footer>
    </div>
  );
};

export default SubscriptionPlans;
